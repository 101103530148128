import React from 'react';
import classNames from "classnames";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getData } from 'components/fetch';
import { Chip, FormControl, InputLabel, makeStyles, TextField } from '@material-ui/core';
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { useDidUpdateEffect } from 'components/Hooks/useDidUpdateEffect';

const useStyles = makeStyles(styles);

export default function CustomAutoComplete(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        success,
        errorText,
        value,
        onChange,
        multiple,
        onBlur,
        disabled,
        required,
    } = props;
    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
    });
    const marginTop = classNames({
        [classes.marginTop]: labelText === undefined
    });

    const [search, setSearch] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([props.value]);

    const GetOptions = async (url) => {
        setLoading(true);
        if (url) {
            const response = await getData(url + `?q=${search}`);
            setLoading(false);
            if (response.ok) {
                setOptions(response.data.data.map(x => {
                    var item = {};
                    item.label = x[props.labelField];
                    item.value = x[props.valueField];
                    if (props.additionalFields) {
                        for (var i = 0; i < props.additionalFields.length; i++) {
                            item[props.additionalFields[i]] = x[props.additionalFields[i]];
                        }
                    }
                    return item;
                }))
                if (props.onGetTotal) {
                    props.onGetTotal(!!response.data.meta ? response.data.meta.total : response.data.data.length);
                }
            }
        } else if (props.items) {
            setOptions(props.items);
            setLoading(false);
        }
    }
    React.useEffect(() => {
        GetOptions(props.url);
    }, [open, search, value]);

    useDidUpdateEffect(() => {
        GetOptions(props.url);
    }, [props.url])

    React.useEffect(() => {
        if (value) {
            setSearch(value.label)
        }
    }, [value]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        } else {
            setSearch('')
        }
    }, [open]);

    return (
        <Autocomplete
            id={id}
            style={{ marginTop: 11 }}
            disabled={disabled}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            multiple={multiple}
            value={value}
            onChange={onChange}
            getOptionSelected={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option.label || ""}
            options={options}
            loading={loading}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option.label}
                        {...getTagProps({ index })}
                        classes={{
                            root: classes.multipleSelectChip
                        }}
                    />
                ))
            }
            onInputChange={(event, value) => setSearch(value)}
            renderInput={(params) => {
                return (
                    <>
                        <TextField {...params} label={labelText} margin="normal"
                            // value={search}
                            // onChange={e => setSearch(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                                onBlur: onBlur,
                                required: required,
                            }}
                        />
                        {errorText !== undefined ? (
                            <InputLabel error shrink>{errorText}</InputLabel>
                        ) : null}
                    </>
                )
            }}
        />
    );
}