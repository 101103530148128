import React, { useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DataTable from "components/Table/DataTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { deleteData } from "components/fetch";
import { useScrollToTop } from "components/ScrollToTop";
import useFetch from "components/fetch";

function ExpenseTypes({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [per_page, setPer_page] = useState(1);
    const [search, setSearch] = useState('');
    useScrollToTop(expenseTypes);

    var response = useFetch([page, search], `api/v1/admin/expense-types?page=${page}&q=${search}`);

    const DeleteExpenseType = async (id) => {
        var response = await deleteData(`api/v1/admin/expense-types/${id}`);
        if (response.ok) {
            setPage(0);
        }
    }
    useEffect(() => {
        if (response && response.ok) {
            setExpenseTypes(response.data.data);
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
            setPer_page(response.data.meta.per_page);
        }
    }, [response])

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Name", "Remark", "Action"]}
                            tableData={
                                expenseTypes.map((expenseType, index) => ({
                                    data: [expenseType.name, expenseType.remark],
                                    onClickEdit: () => history.push(`/expense_type/edit/${expenseType.id}`),
                                    onClickDelete: () => DeleteExpenseType(expenseType.id),
                                    name: expenseType.name
                                }))
                            }
                            onClickCreate={() => history.push(`/expense_type/create`)}
                            count={count}
                            page={page}
                            onPaginationChange={async (event, value) => {
                                await setPage(value)
                            }}
                            onSearch={setSearch}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

export default withRouter(ExpenseTypes)