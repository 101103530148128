import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { InputLabel } from "@material-ui/core";

function CustomerForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [dob, setDob] = useState('')
    const [dobError, setDobError] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [addressError, setAddressError] = useState('')
    const [facebook, setFacebook] = useState('')
    const [facebook_name, setFacebook_name] = useState('')
    const [work_address, setWork_address] = useState('')
    const [city, setCity] = useState('')
    const [township, setTownship] = useState('')
    const [email, setEmail] = useState('')
    const [remark, setRemark] = useState('')

    const GetCustomer = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/customers/${id}`)
            if (response.ok) {
                setName(response.data.data.name);
                setDob(response.data.data.dob);
                setPhone(response.data.data.phone);
                setAddress(response.data.data.address);
                setFacebook(response.data.data.facebook);
                setFacebook_name(response.data.data.facebook_name);
                setWork_address(response.data.data.work_address);
                setCity(response.data.data.city);
                setTownship(response.data.data.township);
                setEmail(response.data.data.email);
                setRemark(response.data.data.remark);
            }
        }
    }

    useEffect(() => {
        GetCustomer(id);
        return () => {
            setName('');
            setNameError('');
        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])
    // useDidUpdateEffect(() => {
    //     setDobError(!dob ? 'This field is required' : '')
    //     return () => {
    //         setDobError('')
    //     }
    // }, [dob])
    useDidUpdateEffect(() => {
        setAddressError(!address ? 'This field is required' : '')
        return () => {
            setAddressError('')
        }
    }, [address])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        // setDobError(!dob ? 'This field is required' : '')
        setAddressError(!address ? 'This field is required' : '')
        if (name && address) {
            var response = await fetchData(id ? `api/v1/admin/customers/${id}` : 'api/v1/admin/customers', { name, dob, phone, address, facebook, facebook_name, work_address, city, township, email, remark }, (id ? 'PUT' : 'POST'));
            // console.log(response)
            if (response.ok) {
                history.push('/customers');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <InputLabel shrink classes={{ root: 'custom-label' }}>Date of Birth (MM/DD/YYYY) *</InputLabel>
                                    <CustomInput
                                        // labelText="Date *"
                                        id="dob"
                                        formControlProps={{
                                            fullWidth: true,
                                            style: { marginTop: 11 }
                                        }}
                                        inputProps={{
                                            type: 'date',
                                            value: dob || '',
                                            onChange: e => setDob(e.target.value),
                                            // onBlur: e => setDobError(!dob ? 'This field is required' : ''),
                                        }}
                                        errorText={dobError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Phone"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: phone || '',
                                            onChange: e => setPhone(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Email"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: email || '',
                                            onChange: e => setEmail(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Facebook"
                                        id="facebook"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: facebook || '',
                                            onChange: e => setFacebook(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Facebook Name"
                                        id="facebook_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: facebook_name || '',
                                            onChange: e => setFacebook_name(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomInput
                                        labelText="Address *"
                                        id="address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: address || '',
                                            onChange: e => setAddress(e.target.value),
                                            onBlur: e => setAddressError(!address ? 'This field is required' : '')
                                        }}
                                        errorText={addressError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomInput
                                        labelText="Work Address"
                                        id="work_address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: work_address || '',
                                            onChange: e => setWork_address(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="City"
                                        id="city"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: city || '',
                                            onChange: e => setCity(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Township"
                                        id="township"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: township || '',
                                            onChange: e => setTownship(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomInput
                                        labelText="Remark"
                                        id="remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: remark || '',
                                            onChange: e => setRemark(e.target.value),
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(CustomerForm);