import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { InputLabel } from "@material-ui/core";
import moment from 'moment';
import CustomAutoComplete from "components/CustomAutoComplete/CustomAutoComplete";

function ExpenseForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [dateError, setDateError] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [amount, setAmount] = useState('')
    const [amountError, setAmountError] = useState('')
    const [expense_type, setExpense_type] = useState(null)
    const [expense_typeError, setExpense_typeError] = useState('')
    const [remark, setRemark] = useState('')

    const GetExpenseType = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/expenses/${id}`)
            if (response.ok) {
                setDate(moment(response.data.data.date).format('YYYY-MM-DD'));
                setDescription(response.data.data.description);
                setAmount(response.data.data.amount);
                setExpense_type({ label: response.data.data.expense_type.name, value: response.data.data.expense_type.id });
                setRemark(response.data.data.remark);
            }
        }
    }

    useEffect(() => {
        GetExpenseType(id);
        return () => {
            setDate('');
            setDateError('');
            setDescription('');
            setDescriptionError('');
            setAmount('');
            setAmountError('');
            setExpense_type(null);
            setExpense_typeError('');
            setRemark('');
        }
    }, [id])
    useDidUpdateEffect(() => {
        setDateError(!date ? 'This field is required' : '')
        return () => {
            setDateError('')
        }
    }, [date])
    useDidUpdateEffect(() => {
        setDescriptionError(!description ? 'This field is required' : '')
        return () => {
            setDescriptionError('')
        }
    }, [description])
    useDidUpdateEffect(() => {
        setAmountError(!amount ? 'This field is required' : '')
        return () => {
            setAmountError('')
        }
    }, [amount])
    useDidUpdateEffect(() => {
        setExpense_typeError(!expense_type ? 'This field is required' : '')
        return () => {
            setExpense_typeError('')
        }
    }, [expense_type])

    const Save = async () => {
        setDateError(!date ? 'This field is required' : '')
        setDescriptionError(!description ? 'This field is required' : '')
        setAmountError(!amount ? 'This field is required' : '')
        setExpense_typeError(!expense_type ? 'This field is required' : '')
        if (date && description && amount && expense_type) {
            var response = await fetchData(id ? `api/v1/admin/expenses/${id}` : 'api/v1/admin/expenses', { date, description, amount, expense_type_id: expense_type.value, remark }, (id ? 'PUT' : 'POST'));
            if (response.ok) {
                history.push('/expenses');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <InputLabel shrink classes={{ root: 'custom-label' }}>Date *</InputLabel>
                                    <CustomInput
                                        // labelText="Date *"
                                        id="date"
                                        formControlProps={{
                                            fullWidth: true,
                                            style: { marginTop: 11 }
                                        }}
                                        inputProps={{
                                            type: 'date',
                                            value: date || '',
                                            onChange: e => setDate(e.target.value),
                                            onBlur: e => setDateError(!date ? 'This field is required' : ''),
                                        }}
                                        errorText={dateError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomAutoComplete
                                        labelText="Expense Type *"
                                        id="expense_type"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={expense_type}
                                        onChange={(event, value) => setExpense_type(value)}
                                        url={`api/v1/admin/expense-types`}
                                        labelField='name'
                                        valueField='id'
                                        required
                                        onBlur={e => setExpense_typeError(!expense_type ? 'This field is required' : '')}
                                        errorText={expense_typeError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomInput
                                        labelText="Description *"
                                        id="description"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: description || '',
                                            onChange: e => setDescription(e.target.value),
                                            onBlur: e => setDescriptionError(!description ? 'This field is required' : '')
                                        }}
                                        errorText={descriptionError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomInput
                                        labelText="Amount *"
                                        id="amount"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'number',
                                            value: amount || '',
                                            onChange: e => setAmount(e.target.value),
                                            onBlur: e => setAmountError(!amount ? 'This field is required' : '')
                                        }}
                                        errorText={amountError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomInput
                                        labelText="Remark"
                                        id="remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: remark || '',
                                            onChange: e => setRemark(e.target.value),
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(ExpenseForm);