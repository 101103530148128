import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData } from "components/fetch";
import { fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import moment from 'moment';
import CustomAutoComplete from "components/CustomAutoComplete/CustomAutoComplete";
import { useCallback } from "react";
import DataTable from "components/Table/DataTable";
import { Done as DoneIcon, Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Array as ArrayFnc } from 'react-native-trifinity';
import { InputLabel } from '@material-ui/core';
import { formatMoney } from "components/common";
import { order_statuses, order_type_enum, order_status_enum } from "components/enum";
import { postData } from "components/fetch";
import debounce from "debounce-promise";
import { deleteData } from "components/fetch";
import { postFormData } from "components/fetch";
import resolution from "components/resolution";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { Lightbox } from "react-modal-image";
import { iterationCopy } from "components/common";
import TextField from '@material-ui/core/TextField';
import { CustomInputWithoutLabel } from "components/CustomInput/CustomInput";

function OrderForm({ history }) {
    const { id } = useParams();
    const location = useLocation();
    const { order_type } = location.state || {};
    const isLoggedIn = useIsLoggedIn();

    const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
    const [dateError, setDateError] = useState('')
    const [customer, setCustomer] = useState(null)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [address_line_1, setAddress_line_1] = useState('')
    const [address_line_2, setAddress_line_2] = useState('')
    const [zip, setZip] = useState('')
    const [payment_method, setPayment_method] = useState('')
    const [payment_methodError, setPayment_methodError] = useState('')
    const [delivery, setDelivery] = useState(null)
    const [sold_date, setSold_date] = useState(null)
    const [sold_dateError, setSold_dateError] = useState('')
    const [status, setStatus] = useState(null)
    const [statusError, setStatusError] = useState('')
    const [remark, setRemark] = useState('')
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState(null)
    const [productError, setProductError] = useState('')
    const [variations, setVariations] = useState([])
    const [variation, setVariation] = useState(null)
    const [variationError, setVariationError] = useState('')
    const [sizes, setSizes] = useState([])
    const [size, setSize] = useState(null)
    const [sizeError, setSizeError] = useState('')
    const [qty, setQty] = useState(1)
    const [qtyError, setQtyError] = useState('')
    const [orderedProducts, setOrderedProducts] = useState([])
    const [unitPrice, setUnitPrice] = useState('');
    const [unitPriceError, setUnitPriceError] = useState('')
    const [changeCustomerCount, setChangeCustomerCount] = useState(0);
    const [barcode, setBarcode] = useState('');
    const [scanBarcodeState, setScanBarcodeState] = useState(""); // adding, notfound
    const [acc_name, setAcc_name] = useState('')
    const [acc_nameError, setAcc_nameError] = useState('')
    const [accounts, setAccounts] = useState([])
    const [images, setImages] = useState([]);
    const [imageUrl, setImageUrl] = useState(null)
    const [delivery_fee, setDelivery_fee] = useState(0)
    const [tax, setTax] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [advanced, setAdvanced] = useState(0)

    const GetOrder = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/orders/${id}`)
            if (response.ok) {
                const { order_date, name, customer, phone, address_line_1, address_line_2, zip, images, payment_method, bank_name, status, delivery, sold_date, order_items, delivery_fee, tax, discount, advanced, remark } = response.data.data;
                setDate(order_date);
                setName(name);
                setPhone(phone);
                setAddress_line_1(address_line_1);
                setAddress_line_2(address_line_2);
                setZip(zip);
                setImages(images);
                // setPayment_method(payment_method ? payment_methods.find(x => x.value === payment_method) : null);
                setPayment_method(payment_method ? { label: payment_method.bank.name, value: payment_method.bank.id } : bank_name ? { label: bank_name } : '');
                setAcc_name(payment_method ? { label: payment_method.acc_name + ' / ' + payment_method.acc_no, value: payment_method.id } : '');
                setStatus(order_statuses.find(x => x.value === status));
                setCustomer(customer ? { label: customer.name, value: customer.id } : null);
                setDelivery(delivery ? { label: delivery.name, value: delivery.id } : null);
                setSold_date(sold_date);
                setOrderedProducts(order_items.map(x => {
                    var _product = x.type === 'size' ? x.orderable_item.variation.product : x.type === "variation" ? x.orderable_item.product : x.orderable_item;
                    var _variation = x.type === 'size' ? x.orderable_item.variation : x.type === "variation" ? x.orderable_item : null;
                    var _size = x.type === 'size' ? x.orderable_item : null;
                    return {
                        type: x.type,
                        qty: x.qty,
                        // purchasable_id: x.purchasable_id,
                        id: x.id,
                        orderable_id: x.orderable_id,
                        brand: _product.brand,
                        sku: _product.sku,
                        image: _product.images[0].url,
                        product: _product.name,
                        variation: _variation?.color || '',
                        size: _size?.name || '',
                        unitPrice: x.price,
                        amount: x.price * x.qty,
                    }
                }))
                setDelivery_fee(delivery_fee);
                setTax(tax);
                setDiscount(discount);
                setAdvanced(advanced);
                setRemark(remark);
            }
        } else {
            setStatus(order_statuses[0]);
        }
    }

    const GetProductByBarcode = async (barcode) => {
        if (barcode) {
            var response = await getData(`api/v1/admin/barcodes/${barcode}/items`)
            if (response.ok) {
                if (response.data.data.length > 0) {
                    return response.data.data;
                    // setProducts(response.data.data);
                    // setProducts(response.data.data.map(x => ({ label: x.type, value: x.id, barcode_item: x.barcode_item })));
                } else {
                    setScanBarcodeState("notfound");
                    setUnitPrice(null);
                    setProducts([]);
                    setProduct(null);
                    setVariations([]);
                    setVariation(null);
                    setSizes([]);
                    setSize(null);
                }
            }
        }
    }

    const debouncedGetItemByBarcode = debounce(GetProductByBarcode, 500, { leading: true });

    const GetVariations = async (product_id) => {
        if (product_id) {
            var response = await getData(`api/v1/admin/products/${product_id}/variations`)
            if (response.ok) {
                if (response.data.data.length > 0) {
                    setVariations(response.data.data.map(x => ({ label: x.color, value: x.id, price: x.price })));
                } else {
                    setUnitPrice(product.price);
                }
            }
        }
    }

    const GetPaymentMethods = async (payment_method_id) => {
        if (payment_method_id) {
            // console.log(payment_method_id);
            var response = await getData(`api/v1/admin/banks/${payment_method_id}/payment_methods`)
            // console.log('payment_method_id',response);
            if (response.ok) {
                if (response.data.data.length > 0) {
                    setAccounts(response.data.data.map(x => ({ label: x.acc_name + ' / ' + x.acc_no, value: x.id })));
                } else {
                    setAccounts([]);
                    setAcc_name('');
                }

            }
        }
    }

    const GetSizes = async (variation_id) => {
        if (variation_id) {
            var response = await getData(`api/v1/admin/variations/${variation_id}`)
            if (response.ok) {
                if (response.data.data.sizes.length > 0) {
                    setSizes(response.data.data.sizes.map(x => ({ label: x.name, value: x.id, price: x.price })));
                } else {
                    setUnitPrice(variation.price)
                }
            }
        }
    }

    const openImagebox = (img) => {
        setImageUrl(img.url);
    };

    const closeLightbox = () => {
        setImageUrl(null);
    };
    useEffect(() => {
        GetOrder(id);
        return () => {
            setDate('');
            setDateError('');
            setRemark('');
        }
    }, [id])

    useEffect(() => {
        if (product) {
            GetVariations(product.value)
        }
        setVariations([]);
        setVariation(null);
        setSizes([]);
        setSize(null);

    }, [product])

    useEffect(() => {
        if (payment_method) {
            GetPaymentMethods(payment_method.value)
        }
    }, [payment_method])

    useEffect(() => {
        if (variation) {
            GetSizes(variation.value)
        }
        setSizes([]);
        setSize(null);
    }, [variation])

    useEffect(() => {
        setUnitPrice(size?.price || '')
    }, [size])

    // useEffect(() => {
    //     if (barcode && scanBarcodeState === "adding") {
    //         debouncedGetItemByBarcode(barcode);
    //     } else {
    //         setScanBarcodeState("notfound");
    //     }
    // }, [barcode, scanBarcodeState])

    // Images
    // const GetImages = async (id) => {
    //     var response = await getData(`api/v1/admin/orders/${id}/images`);
    //     console.log("customerImg",response)
    //     if (response.ok) {
    //         setImages(response.data.data.map(x => ({ ...x, data_url: x.url })));
    //     }
    // }
    // const DeleteImage = async (id) => {
    //     var response = await deleteData(`api/v1/admin/images/${id}`);
    //     if (response.ok) {
    //     }
    // }
    // const PutImage = async (id, image) => {
    //     var response = await postFormData(`api/v1/admin/images/${id}`, { image });
    //     if (response.ok) {
    //     }
    // }
    // const PostImage = async (id, image) => { 
    //     var response = await postFormData(`api/v1/admin/orders/${id}/images`, { image });
    //     if (response.ok) {
    //     }
    // }

    // useEffect(() => {
    //     GetImages(id);
    // }, [id])

    useEffect(() => {
        const addItem = async () => {
            var items = await debouncedGetItemByBarcode(barcode);
            if (items) {
                items.map((item, i) => {
                    if (item.type === "product") {
                        var orderedProduct = {
                            id: null,
                            type: item.type,
                            qty: qty,
                            orderable_id: item.id,
                            brand: item.barcode_item.brand,
                            sku: item.barcode_item.sku,
                            image: item.barcode_item.images[0].url,
                            product: item.barcode_item.name,
                            variation: '',
                            size: '',
                            unitPrice: item.barcode_item.price,
                            amount: item.barcode_item.price * qty,
                        };
                        orderedProducts.push(orderedProduct);
                        setOrderedProducts(ArrayFnc.clone(orderedProducts));
                        setScanBarcodeState("");
                        setProduct(null);
                        setVariations([]);
                        setVariation(null);
                        setSizes([]);
                        setSize(null);
                        setQty(1);
                        setUnitPrice('');
                    } else if (item.type === "variation") {
                        var orderedProduct = {
                            id: null,
                            type: item.type,
                            qty: qty,
                            orderable_id: item.id,
                            brand: item.barcode_item.product.brand,
                            sku: item.barcode_item.product.sku,
                            image: item.barcode_item.product.images[0].url,
                            product: item.barcode_item.product.name,
                            variation: item.barcode_item.color,
                            size: '',
                            unitPrice: item.barcode_item.price,
                            amount: item.barcode_item.price * qty,
                        };
                        orderedProducts.push(orderedProduct);
                        setOrderedProducts(ArrayFnc.clone(orderedProducts));
                        setScanBarcodeState("");
                        setProduct(null);
                        setVariations([]);
                        setVariation(null);
                        setSizes([]);
                        setSize(null);
                        setQty(1);
                        setUnitPrice('');
                    } else if (item.type === "size") {
                        var orderedProduct = {
                            id: null,
                            type: item.type,
                            qty: qty,
                            orderable_id: item.id,
                            brand: item.barcode_item.variation.product.brand,
                            sku: item.barcode_item.variation.product.sku,
                            image: item.barcode_item.variation.product.images[0].url,
                            product: item.barcode_item.variation.product.name,
                            variation: item.barcode_item.variation.color,
                            size: item.barcode_item.name,
                            unitPrice: item.barcode_item.price,
                            amount: item.barcode_item.price * qty,
                        };
                        orderedProducts.push(orderedProduct);
                        setOrderedProducts(ArrayFnc.clone(orderedProducts));
                        setScanBarcodeState("");
                        setProduct(null);
                        setVariations([]);
                        setVariation(null);
                        setSizes([]);
                        setSize(null);
                        setQty(1);
                        setUnitPrice('');
                    } else {
                        setOrderedProducts(ArrayFnc.clone(orderedProducts));
                        setScanBarcodeState("notfound");
                        setBarcode('');
                        setProduct(null);
                        setVariations([]);
                        setVariation(null);
                        setSizes([]);
                        setSize(null);
                        setQty(1);
                        setUnitPrice('');
                    }
                })
            } else {
                setScanBarcodeState("notfound");
            }
            setBarcode("");
        }

        if (barcode && scanBarcodeState === "adding") {
            addItem();
        }
    }, [scanBarcodeState])

    useDidUpdateEffect(() => {
        setPhone(customer?.phone || '')
        setAddress_line_1(customer?.address || '')
        setAddress_line_2(`${customer?.township || ''}${customer?.city ? (customer?.township ? ', ' : '') + customer?.city : ''}`)
    }, [changeCustomerCount])

    // useEffect(() => {
    //     console.log(variations)
    // }, [variations])

    // useDidUpdateEffect(() => {
    //     if (products) {
    //         products.map((item, i) => {
    //             if (item.type === "product") {
    //                 setProduct({ label: item.barcode_item.name, value: item.barcode_item.id, price: item.barcode_item.price, images: item.barcode_item.images, brand: item.barcode_item.brand, sku: item.barcode_item.sku });
    //                 setUnitPrice(item.barcode_item.price);
    //                 setVariations([]);
    //                 setVariation(null);
    //                 setSize(null);
    //             } else if (item.type === "variation") {
    //                 setProduct({ label: item.barcode_item.product.name, value: item.barcode_item.product.id, images: item.barcode_item.product.images, brand: item.barcode_item.product.brand, sku: item.barcode_item.product.sku });
    //                 setVariation({ label: item.barcode_item.color, value: item.barcode_item.id, price: item.barcode_item.price });
    //                 setVariations({ label: item.barcode_item.color, value: item.barcode_item.id, price: item.barcode_item.price });
    //                 setUnitPrice(item.barcode_item.price);
    //                 setSizes([])
    //                 setSize(null);
    //             } else if (item.type === "size") {
    //                 setProduct({ label: item.barcode_item.variation.product.name, value: item.barcode_item.variation.product.id, images: item.barcode_item.variation.product.images, brand: item.barcode_item.variation.product.brand, sku: item.barcode_item.variation.product.sku });
    //                 setVariation({ label: item.barcode_item.variation.color, value: item.barcode_item.variation.id });
    //                 setVariations({ label: item.barcode_item.variation.color, value: item.barcode_item.variation.id });
    //                 setSize({ label: item.barcode_item.name, value: item.barcode_item.id, price: item.barcode_item.price });
    //                 setSizes({ label: item.barcode_item.name, value: item.barcode_item.id, price: item.barcode_item.price });
    //                 setUnitPrice(item.barcode_item.price);
    //             } else {
    //                 setUnitPrice(null);
    //                 setProducts([]);
    //                 setProduct(null);
    //                 setVariations([]);
    //                 setVariation(null);
    //                 setSizes([]);
    //                 setSize(null);
    //             }
    //             // setProduct(products[0]);
    //         })
    //     } else {
    //         setUnitPrice(null);
    //         setProducts([]);
    //         setProduct(null);
    //         setVariations([]);
    //         setVariation(null);
    //         setSizes([]);
    //         setSize(null);
    //     }
    // }, [products])

    useDidUpdateEffect(() => {
        setDateError(!date ? 'This field is required' : '')
        return () => {
            setDateError('')
        }
    }, [date])

    useDidUpdateEffect(() => {
        setSold_dateError(!sold_date ? 'This field is required' : '')
        return () => {
            setSold_dateError('')
        }
    }, [sold_date])

    useDidUpdateEffect(() => {
        setPayment_methodError(!payment_method ? 'This field is required' : '')
        return () => {
            setPayment_methodError('')
        }
    }, [payment_method])

    useDidUpdateEffect(() => {
        setAcc_nameError(!acc_name ? 'This field is required' : '')
        return () => {
            setAcc_nameError('')
        }
    }, [acc_name])

    useDidUpdateEffect(() => {
        setStatusError(!status ? 'This field is required' : '')
        if (status?.value === order_status_enum.delivered) {
            setSold_date(moment().format("YYYY-MM-DD"));
        } else {
            setSold_date(null);
        }
        return () => {
            setStatusError('')
        }
    }, [status])

    useDidUpdateEffect(() => {
        if (unitPrice) {
            setUnitPriceError('')
        }
        return () => {
            setUnitPriceError('')
        }
    }, [unitPrice])

    useDidUpdateEffect(() => {
        if (qty) {
            setQtyError('')
        }
        return () => {
            setQtyError('')
        }
    }, [qty])

    useDidUpdateEffect(() => {
        if (product) {
            setProductError('')
        }
        return () => {
            setProductError('')
        }
    }, [product])

    useDidUpdateEffect(() => {
        if (variation) {
            setVariationError('')
        }
        return () => {
            setVariationError('')
        }
    }, [variation])

    useDidUpdateEffect(() => {
        if (size) {
            setSizeError('')
        }
        return () => {
            setSizeError('')
        }
    }, [size])

    const Add = async () => {
        setProductError(!product ? 'This field is required' : '');
        setVariationError((variations.length > 0 && !variation) ? 'This field is required' : '');
        setSizeError((sizes.length > 0 && !size) ? 'This field is required' : '');
        setQtyError((!qty || qty <= 0) ? 'This field is required' : '');
        setUnitPriceError((!unitPrice || unitPrice <= 0) ? 'This field is required' : '')
        if (product && product.value && (variations.length <= 0 || (variation && variation.value)) && (sizes.length <= 0 || (size && size.value)) && unitPrice && unitPrice > 0 && qty && qty > 0) {
            var orderedProduct = {
                id: null,
                type: size ? 'size' : variation ? 'variation' : 'product',
                qty: qty,
                orderable_id: size ? size.value : variation ? variation.value : product.value,
                brand: product.brand,
                sku: product.sku,
                image: product.images[0].url,
                product: product.label,
                variation: variation?.label || '',
                size: size?.label || '',
                unitPrice,
                amount: unitPrice * qty,
            };
            orderedProducts.push(orderedProduct);
            setOrderedProducts(ArrayFnc.clone(orderedProducts));
            setScanBarcodeState("notfound");
            setBarcode('');
            setProduct(null);
            setVariations([]);
            setVariation(null);
            setSizes([]);
            setSize(null);
            setQty(1);
            setUnitPrice('');
        }
    }

    const Delete = (index) => {
        ArrayFnc.arrayRemoveByIndex(orderedProducts, index);
        setOrderedProducts(ArrayFnc.clone(orderedProducts));
    }

    const Save = async (e) => {
        e.preventDefault();
        setDateError(!date ? 'This field is required' : '')
        setStatusError(!status.value ? 'This field is required' : '')
        // setPayment_methodError(!payment_method.value ? 'This field is required' : '')
        setSold_dateError(!sold_date && status?.value === order_status_enum.delivered ? 'This field is required' : '')
        if (id && order_type === order_type_enum.checkout && status && ((sold_date && status?.value === order_status_enum.delivered) || status?.value !== order_status_enum.delivered)) {
            var response = await postData(`api/v1/admin/orders/${id}/status`, { status: status.value, sold_date: sold_date });
            // console.log("status",response)
            if (response.ok) {
                history.push('/orders');
            }
        } else if (order_type === order_type_enum.sale) {
            if (orderedProducts.length <= 0) {
                alert('Please add ordered products.')
            } else if (date && (customer?.value || name) && phone && address_line_1 && status.value && ((sold_date && status?.value === order_status_enum.delivered) || status?.value !== order_status_enum.delivered)) {
                var response = await postFormData(id ? `api/v1/admin/orders/${id}` : 'api/v1/admin/orders', {
                    order_date: date, name: name ? name : customer.label, phone, address_line_1, address_line_2, zip, remark,
                    customer_id: customer?.value || '',
                    status: status.value,
                    // payment_method: payment_method.value,
                    images: id ? [] : images ? images.map(x => x.file) : '',
                    bank_name: payment_method?.label || '',
                    delivery_id: delivery?.value || '',
                    payment_method_id: acc_name?.value || '',
                    sold_date: sold_date ? sold_date : '',
                    order_items: orderedProducts.map(x => ({
                        type: x.type, qty: x.qty, orderable_id: x.orderable_id, price: x.unitPrice, id: x.id || ''
                    })),
                    delivery_fee, tax, discount, advanced
                }, 'POST');
                if (response.ok) {
                    history.push(order_type === order_type_enum.checkout ? '/orders' : '/sales');
                }
            }
        }
    }
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    else if (!order_type) return <></>
    return (
        <form onSubmit={Save}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                {/* {
                                    images && images.length > 0 ?
                                        <GridItem xs={12} sm={12} md={12}>
                                            <ImageUploader value={images}
                                                onViewImage={(index) => {alert(index)}}
                                                dataURLKey={'url'}
                                                resolutionProps={resolution.product}
                                                onChange={(images, addUpdateIndex, action) => { setImages(images); }}
                                                onImageUpload={(imageList) => {
                                                    imageList.map(image => {
                                                        PostImage(id, image.file)
                                                    })
                                                }}
                                                onImageUpdate={(imageList) => {
                                                    imageList.map(image => {
                                                        PutImage(image.old.id, image.new.file)
                                                    })
                                                }}
                                                onImageRemove={(image) => {
                                                    if (image.id) {
                                                        DeleteImage(image.id)
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        :  */}
                                <GridItem xs={12} sm={12} md={12}>
                                    {/* <div style={{ width: '100%', height: 100 }}><span>Loading...</span></div> */}
                                    {/* <ImageUploader
                                        labelText={'Payment Images * - Click / Drop here'}
                                        dataURLKey={'url'}
                                        onViewImage={(image) => { openImagebox(image) }}
                                        value={images}
                                        onChange={(images, addUpdateIndex, action) => { setImages(images) }}
                                    /> */}
                                </GridItem>
                                {/* } */}
                                <GridItem xs={12} sm={12} md={4}>
                                    <InputLabel shrink classes={{ root: 'custom-label' }}>Date *</InputLabel>
                                    <CustomInput
                                        // labelText="Date *"
                                        id="date"
                                        formControlProps={{
                                            fullWidth: true,
                                            style: { marginTop: 11 }
                                        }}
                                        inputProps={{
                                            type: 'date',
                                            value: date || '',
                                            onChange: e => setDate(e.target.value),
                                            onBlur: e => setDateError(!date ? 'This field is required' : ''),
                                            disabled: !!id && order_type === order_type_enum.checkout
                                        }}
                                        errorText={dateError}
                                    />
                                </GridItem>
                                {
                                    order_type === order_type_enum.checkout ?
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Customer *"
                                                id="name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    value: name,
                                                    onChange: e => setName(e.target.value),
                                                    required: true,
                                                    disabled: !!id && order_type === order_type_enum.checkout
                                                }}
                                            />
                                        </GridItem>
                                        :
                                        order_type === order_type_enum.sale ?
                                            <>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <CustomAutoComplete
                                                        labelText="Customer *"
                                                        id="customer"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        value={customer}
                                                        onChange={(event, value) => { setCustomer(value); setChangeCustomerCount(count => count + 1) }}
                                                        url={`api/v1/admin/customers`}
                                                        labelField='name'
                                                        valueField='id'
                                                        additionalFields={['phone', 'address', 'township', 'city']}
                                                    />
                                                </GridItem>
                                                {
                                                    customer ? <GridItem xs={12} sm={12} md={4} /> :
                                                        <GridItem xs={12} sm={12} md={4}>
                                                            <CustomInput
                                                                labelText="Guest Customer Name *"
                                                                id="name"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    type: 'text',
                                                                    value: name,
                                                                    onChange: e => setName(e.target.value),
                                                                    required: true,
                                                                }}
                                                            />
                                                        </GridItem>

                                                }
                                            </>
                                            : <></>
                                }
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Phone *"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'text',
                                            value: phone,
                                            onChange: e => setPhone(e.target.value),
                                            required: true,
                                            disabled: !!id && order_type === order_type_enum.checkout
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Address Line 1 *"
                                        id="address_line_1"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'text',
                                            value: address_line_1,
                                            onChange: e => setAddress_line_1(e.target.value),
                                            required: true,
                                            disabled: !!id && order_type === order_type_enum.checkout
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Address Line 2"
                                        id="address_line_2"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'text',
                                            value: address_line_2,
                                            onChange: e => setAddress_line_2(e.target.value),
                                            disabled: !!id && order_type === order_type_enum.checkout
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText={order_type === order_type_enum.checkout ? "Zip *" : 'Zip'}
                                        id="zip"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'number',
                                            value: zip,
                                            onChange: e => setZip(e.target.value),
                                            required: order_type === order_type_enum.checkout,
                                            disabled: !!id && order_type === order_type_enum.checkout
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomAutoComplete
                                        labelText="Status *"
                                        id="status"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={status}
                                        onChange={(event, value) => setStatus(value)}
                                        onBlur={() => setStatusError(!status ? 'This field is required' : '')}
                                        items={order_statuses}
                                        errorText={statusError}
                                        required
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomAutoComplete
                                        labelText="Delivery"
                                        id="delivery"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={delivery}
                                        onChange={(event, value) => setDelivery(value)}
                                        url={`api/v1/admin/deliveries`}
                                        labelField='name'
                                        valueField='id'
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomAutoComplete
                                        labelText="Bank *"
                                        id="payment_method"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={payment_method}
                                        onChange={(event, value) => setPayment_method(value)}
                                        onBlur={() => setPayment_methodError(!payment_method ? 'This field is required' : '')}
                                        errorText={payment_methodError}
                                        // disabled={!!id && order_type === order_type_enum.checkout}
                                        required
                                        url={`api/v1/admin/banks`}
                                        labelField='name'
                                        valueField='id'
                                    />
                                </GridItem>
                                {
                                    accounts.length > 0 &&
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomAutoComplete
                                            labelText="Account Name/No *"
                                            id="id"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={acc_name}
                                            onChange={(event, value) => setAcc_name(value)}
                                            onBlur={() => setAcc_nameError(!acc_name ? 'This field is required' : '')}
                                            items={accounts}
                                            // url={`api/v1/admin/banks/${(category?.value || '0')}/sub_categories`}
                                            // labelField='name'
                                            // valueField='id'
                                            errorText={acc_nameError}
                                            required
                                        // disabled={!!id && order_type === order_type_enum.checkout}
                                        />
                                    </GridItem>
                                }
                                {
                                    status?.value === order_status_enum.delivered ?
                                        <GridItem xs={12} sm={12} md={4}>
                                            <InputLabel shrink classes={{ root: 'custom-label' }}>Sale Date *</InputLabel>
                                            <CustomInput
                                                // labelText="Sale Date *"
                                                id="sold_date"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    style: { marginTop: 11 }
                                                }}
                                                inputProps={{
                                                    type: 'date',
                                                    value: sold_date || '',
                                                    onChange: e => setSold_date(e.target.value),
                                                    onBlur: e => setSold_dateError((!sold_date && status?.value === order_status_enum.delivered) ? 'This field is required' : ''),
                                                    // disabled: !!id && order_type === order_type_enum.checkout
                                                }}
                                                errorText={sold_dateError}
                                            />
                                        </GridItem> : <></>
                                }
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardBody>
                                            <b>Order Items</b>
                                            {
                                                !id || (id && order_type === order_type_enum.sale) ?
                                                    <>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <CustomInput
                                                                    labelText="Scan barcode to add *"
                                                                    id="barcode_id"
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        type: 'text',
                                                                        value: barcode,
                                                                        onKeyPress: e => {
                                                                            if (e.charCode === 13) {
                                                                                e.preventDefault();
                                                                                setScanBarcodeState("adding");
                                                                            }
                                                                        },
                                                                        onChange: e => setBarcode(e.target.value),
                                                                        disabled: !!id && order_type === order_type_enum.checkout
                                                                    }}
                                                                />
                                                                <span style={{ color: 'rgba(0, 0, 0, 0.54)', float: 'left' }}>
                                                                    {
                                                                        scanBarcodeState === "adding" ? "Adding..." : (scanBarcodeState === "notfound" ? "Not found" : "")
                                                                    }
                                                                </span>
                                                            </GridItem>
                                                            {
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <CustomAutoComplete
                                                                        labelText="Product *"
                                                                        id="product_id"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        value={product}
                                                                        onChange={(event, value) => setProduct(value)}
                                                                        // onBlur={() => setProductError(!product ? 'This field is required' : '')}
                                                                        url={`api/v1/admin/products`}
                                                                        labelField='name'
                                                                        valueField='id'
                                                                        additionalFields={['price', 'images', 'brand', 'sku']}
                                                                        // items={products}
                                                                        errorText={productError}
                                                                    />
                                                                </GridItem>
                                                            }
                                                            {
                                                                variations.length > 0 &&
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <CustomAutoComplete
                                                                        labelText="Variation *"
                                                                        id="variation_id"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        value={variation}
                                                                        onChange={(event, value) => setVariation(value)}
                                                                        // onBlur={() => setVariationError((variations.length > 0 && !variation) ? 'This field is required' : '')}
                                                                        items={variations}
                                                                        errorText={variationError}
                                                                    />
                                                                </GridItem>
                                                            }
                                                            {
                                                                sizes.length > 0 &&
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <CustomAutoComplete
                                                                        labelText="Size *"
                                                                        id="size_id"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        value={size}
                                                                        onChange={(event, value) => setSize(value)}
                                                                        // onBlur={() => setSizeError((sizes.length > 0 && !size) ? 'This field is required' : '')}
                                                                        items={sizes}
                                                                        errorText={sizeError}
                                                                    />
                                                                </GridItem>
                                                            }
                                                        </GridContainer>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={3}>
                                                                <CustomInput
                                                                    labelText="Unit Price *"
                                                                    id="unitPrice"
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        type: 'number',
                                                                        value: unitPrice || '',
                                                                        min: 0,
                                                                        onChange: e => setUnitPrice(e.target.value),
                                                                        // onBlur: e => setUnitPriceError(!unitPriceError ? 'This field is required' : '')
                                                                    }}
                                                                    errorText={unitPriceError}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={3}>
                                                                <CustomInput
                                                                    labelText="Qty *"
                                                                    id="qty"
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        type: 'number',
                                                                        value: qty,
                                                                        min: 1,
                                                                        onChange: e => setQty(e.target.value),
                                                                        // onBlur: e => setQtyError(!qty ? 'This field is required' : '')
                                                                    }}
                                                                    errorText={qtyError}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                <Button color="primary" onClick={Add}>Add</Button>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </>
                                                    : <></>
                                            }
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <DataTable
                                                        tableHeaderColor="primary"
                                                        tableHead={["Item", 'UnitPrice', 'Qty', 'Amount', !id || (id && order_type === order_type_enum.checkout) ? '' : "Action"]}
                                                        tableData={
                                                            orderedProducts.map((orderedProduct, index) => {
                                                                return ({
                                                                    data: [(
                                                                        <div style={{ display: 'flex' }}>
                                                                            <img src={orderedProduct.image} style={{
                                                                                width: 97,
                                                                                height: 97,
                                                                                objectFit: 'contain',
                                                                                objectPosition: 'center center',
                                                                            }} />
                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: '0 15px' }}>
                                                                                <b>{orderedProduct.brand.name}</b>
                                                                                <span>{orderedProduct.product}</span>
                                                                                <div style={{ marginTop: 4, display: 'flex', flexDirection: 'column' }}>
                                                                                    <span style={{ color: 'rgb(117, 117, 117)' }}>{orderedProduct.sku}</span>
                                                                                    {
                                                                                        orderedProduct.variation ? <span>Type/Color: {orderedProduct.variation ? orderedProduct.size ? `${orderedProduct.variation}/${orderedProduct.size}` : orderedProduct.variation : ''}</span> : <></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ), (
                                                                        <>
                                                                            {
                                                                                orderedProduct.status == "edit" ? (
                                                                                    <EditableTextField id={`unitPrice_${index}`} className={"edit-unitPrice"} defaultValue={orderedProduct.unitPrice} />
                                                                                ) : <p className="edit-unitPrice">{`${formatMoney(orderedProduct.unitPrice)} MMK`}</p>
                                                                            }
                                                                        </>
                                                                    ),
                                                                    (
                                                                        <>
                                                                            {
                                                                                orderedProduct.status == "edit" ? (
                                                                                    <EditableTextField id={`qty_${index}`} className={"edit-qty"} defaultValue={orderedProduct.qty} />
                                                                                ) : <p className="edit-qty">{orderedProduct.qty}</p>
                                                                            }
                                                                        </>
                                                                    ), `${formatMoney(orderedProduct.amount)} MMK`],
                                                                    customActions: (
                                                                        (id && order_type === order_type_enum.checkout) ? <></> : orderedProducts[index]["status"] == "edit" ?
                                                                            <Button className='iconbtn' color="danger"
                                                                                onClick={() => {
                                                                                    var unitPrice = document.getElementById(`unitPrice_${index}`).value;
                                                                                    var qty = document.getElementById(`qty_${index}`).value;
                                                                                    orderedProducts[index]["unitPrice"] = unitPrice;
                                                                                    orderedProducts[index]["qty"] = qty;
                                                                                    orderedProducts[index]["amount"] = unitPrice * qty;
                                                                                    orderedProducts[index]["status"] = "";
                                                                                    setOrderedProducts(JSON.parse(JSON.stringify(orderedProducts)));
                                                                                }} tooltip={{ title: 'Save' }}
                                                                            >
                                                                                <DoneIcon />
                                                                            </Button> :
                                                                            <>
                                                                                <Button className='iconbtn' color="danger" onClick={() => { orderedProducts[index]["status"] = "edit"; setOrderedProducts(JSON.parse(JSON.stringify(orderedProducts))); }} tooltip={{ title: 'Edit' }}><EditIcon /></Button>
                                                                                <Button className='iconbtn' color="danger" onClick={() => Delete(index)} tooltip={{ title: 'Delete' }}><DeleteIcon /></Button>
                                                                            </>
                                                                    )
                                                                })
                                                            })
                                                        }
                                                        tableFooterData={[
                                                            { data: ['', '', 'Sub Total', (<span className="number-col">{formatMoney(ArrayFnc.sum(orderedProducts, 'amount'))} MMK</span>)] },
                                                            {
                                                                data: ['', '', 'Delivery', (
                                                                    <CustomInputWithoutLabel
                                                                        id="delivery_fee"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            type: 'number',
                                                                            defaultValue: delivery_fee,
                                                                            inputProps: { style: { textAlign: 'right' } },
                                                                            onBlur: e => setDelivery_fee(e.target.value),
                                                                        }}
                                                                    />
                                                                )]
                                                            },
                                                            {
                                                                data: ['', '', 'Tax (Included)', (
                                                                    <CustomInputWithoutLabel
                                                                        id="tax"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            type: 'number',
                                                                            defaultValue: tax,
                                                                            inputProps: { style: { textAlign: 'right' } },
                                                                            onBlur: e => setTax(e.target.value),
                                                                        }}
                                                                    />
                                                                )]
                                                            },
                                                            {
                                                                data: ['', '', 'Discount', (
                                                                    <CustomInputWithoutLabel
                                                                        id="discount"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            type: 'number',
                                                                            defaultValue: discount,
                                                                            inputProps: { style: { textAlign: 'right' } },
                                                                            onBlur: e => setDiscount(e.target.value),
                                                                        }}
                                                                    />
                                                                )]
                                                            },
                                                            { data: ['', '', 'Total', (<span className="number-col">{formatMoney(ArrayFnc.sum(orderedProducts, 'amount') - discount)} MMK</span>)] },
                                                            {
                                                                data: ['', '', 'Advance', (
                                                                    <CustomInputWithoutLabel
                                                                        id="advance"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            type: 'number',
                                                                            defaultValue: advanced,
                                                                            inputProps: { style: { textAlign: 'right' } },
                                                                            onBlur: e => setAdvanced(e.target.value),
                                                                        }}
                                                                    />
                                                                )]
                                                            },
                                                            { data: ['', '', 'Paid', (<span className="number-col">{formatMoney(ArrayFnc.sum(orderedProducts, 'amount') - discount - advanced)} MMK</span>)] },
                                                        ]}
                                                        disableCreate
                                                        disablePagination
                                                        disableSearch
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                {/* {
                                    order_type === order_type_enum.sale ?
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Remark"
                                                id="remark"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: remark || '',
                                                    onChange: e => setRemark(e.target.value),
                                                    disabled: !!id && order_type === order_type_enum.checkout,
                                                }}
                                            />
                                        </GridItem> : <></>
                                } */}
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" type="submit">Save</Button>
                        </CardFooter>
                    </Card>
                    {
                        imageUrl ?
                            <Lightbox
                                large={imageUrl}
                                alt="Image"
                                onClose={closeLightbox}
                            />
                            :
                            <></>
                    }
                </GridItem>
            </GridContainer>
        </form>
    );
}
export default withRouter(OrderForm);

export const EditableTextField = props => {
    const { defaultValue, id, className } = props;
    const [value, setValue] = useState(defaultValue);

    return (
        <TextField id={id} className={`edit-number ${className}`} variant="outlined"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}
        />
    )
}
