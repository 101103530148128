export const order_status_enum = Object.freeze({
    new: 'new',
    confirmed: 'confirmed',
    delivered: 'delivered',
    canceled: 'canceled',
})

export const order_statuses = [
    { label: 'new', value: 'new' },
    { label: 'confirmed', value: 'confirmed' },
    { label: 'delivered', value: 'delivered' },
    { label: 'canceled', value: 'canceled' },
]

export const order_type_enum = Object.freeze({
    checkout: 'checkout', // Order by customer
    sale: 'sale', // Order by admin
})

// export const payment_methods = [
//     { label: 'Cash', value: 'cash' },
//     { label: 'KBZ', value: 'kbz' },
// ]