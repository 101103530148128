/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import { Dashboard as DashboardIcon, ViewCarousel as ViewCarouselIcon, BrandingWatermark as BrandingWatermarkIcon, Category as CategoryIcon, Label as LabelIcon, AddShoppingCart as AddShoppingCartIcon, ShoppingCart as ShoppingCartIcon, ShoppingBasket as ShoppingBasketIcon, PlaylistAddCheck as PlaylistAddCheckIcon, MoveToInbox as MoveToInboxIcon, AccountBox as AccountBoxIcon, ContactPhone as ContactPhoneIcon, DirectionsBike as DirectionsBikeIcon, MoneyOff as MoneyOffIcon, ExitToApp as ExitToAppIcon } from "@material-ui/icons";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import Brands from "views/Brand/Brands";
import BrandForm from "views/Brand/BrandForm";
import CategoryForm from "views/Category/CategoryForm";
import Categories from "views/Category/Categories";
import Products from "views/Product/Products";
import ProductForm from "views/Product/ProductForm";
import Login from "views/Auth/Login";
import SubCategories from "views/SubCategory/SubCategories";
import SubCategoryForm from "views/SubCategory/SubCategoryForm";
import TagForm from "views/Tag/TagForm";
import Tags from "views/Tag/Tags";
import ProductVariations from "views/Product/ProductVariations";
import Logout from "views/Auth/Logout";
import Home from "views/Home/Home";
import CarouselsAndBanners from "views/Brand/CarouselsAndBanners";
import Purchases from "views/Purchase/Purchases";
import PurchaseForm from "views/Purchase/PurchaseForm";
import Grounding from "views/Grounding/Grounding";
import { Orders, Sales } from "views/Order/Orders";
import OrderForm from "views/Order/OrderForm";
import Suppliers from "views/Supplier/Suppliers";
import SupplierForm from "views/Supplier/SupplierForm";
import Customers from "views/Customer/Customers";
import CustomerForm from "views/Customer/CustomerForm";
import ExpenseTypeForm from "views/ExpenseType/ExpenseTypeForm";
import ExpenseTypes from "views/ExpenseType/ExpenseTypes";
import ExpenseForm from "views/Expense/ExpenseForm";
import Expenses from "views/Expense/Expenses";
import DeliveryForm from "views/Delivery/DeliveryForm";
import Deliveries from "views/Delivery/Deliveries";
import BankForm from "views/Banks/BankForm";
import Banks from "views/Banks/Banks";
import PaymentMethodForm from "views/PaymentMethod/PaymentMethodForm";
import PaymentMethods from "views/PaymentMethod/PaymentMethods";
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Login,
    layout: "/auth",
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: DashboardIcon,
  //   component: DashboardPage,
  //   layout: "/admin"
  // },
  {
    path: "/home",
    name: "Home Page",
    rtlName: "لوحة القيادة",
    icon: ViewCarouselIcon,
    component: Home,
    layout: "/admin"
  },
  {
    path: "/brands",
    name: "Brands",
    rtlName: "لوحة القيادة",
    icon: BrandingWatermarkIcon,
    component: Brands,
    layout: "/admin"
  },
  {
    path: "/brand/create",
    name: "Create Brand",
    rtlName: "لوحة القيادة",
    icon: BrandingWatermarkIcon,
    component: BrandForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/brand/edit/:id",
    name: "Edit Brand",
    rtlName: "لوحة القيادة",
    icon: BrandingWatermarkIcon,
    component: BrandForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/brand/carousels_and_banners/:id",
    name: "Carousels and Banners",
    rtlName: "لوحة القيادة",
    icon: BrandingWatermarkIcon,
    component: CarouselsAndBanners,
    layout: "/admin",
    hide: true
  },
  {
    path: "/categories",
    name: "Categories",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: Categories,
    layout: "/admin"
  },
  {
    path: "/category/create",
    name: "Create Category",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: CategoryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/category/edit/:id",
    name: "Edit Category",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: CategoryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/sub_categories",
    name: "Sub Categories",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: SubCategories,
    layout: "/admin"
  },
  {
    path: "/sub_category/create",
    name: "Create Sub Category",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: SubCategoryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/sub_category/edit/:id",
    name: "Edit Sub Category",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: SubCategoryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/banks",
    name: "Payment Methods",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceIcon,
    component: Banks,
    layout: "/admin"
  },
  {
    path: "/bank/create",
    name: "Create Payment Method",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceIcon,
    component: BankForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/bank/edit/:id",
    name: "Edit Payment Methods",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceIcon,
    component: BankForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/payment_methods",
    name: "Bank Accounts",
    rtlName: "لوحة القيادة",
    icon: PaymentIcon,
    component: PaymentMethods,
    layout: "/admin"
  },
  {
    path: "/payment_method/create",
    name: "Create Bank Account",
    rtlName: "لوحة القيادة",
    icon: PaymentIcon,
    component: PaymentMethodForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/payment_method/edit/:id",
    name: "Edit Bank Account",
    rtlName: "لوحة القيادة",
    icon: PaymentIcon,
    component: PaymentMethodForm,
    layout: "/admin",
    hide: true
  },
  // {
  //   path: "/tags",
  //   name: "Tags",
  //   rtlName: "لوحة القيادة",
  //   icon: LabelIcon,
  //   component: Tags,
  //   layout: "/admin"
  // },
  {
    path: "/tag/create",
    name: "Create Tag",
    rtlName: "لوحة القيادة",
    icon: LabelIcon,
    component: TagForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/tag/edit/:id",
    name: "Edit Tag",
    rtlName: "لوحة القيادة",
    icon: LabelIcon,
    component: TagForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "لوحة القيادة",
    icon: AddShoppingCartIcon,
    component: Orders,
    layout: "/admin"
  },
  {
    path: "/order/create",
    name: "Create Order",
    rtlName: "لوحة القيادة",
    icon: AddShoppingCartIcon,
    component: OrderForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/order/edit/:id",
    name: "Edit Order",
    rtlName: "لوحة القيادة",
    icon: AddShoppingCartIcon,
    component: OrderForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/sales",
    name: "Sales",
    rtlName: "لوحة القيادة",
    icon: ShoppingCartIcon,
    component: Sales,
    layout: "/admin"
  },
  {
    path: "/sale/create",
    name: "Create Sale",
    rtlName: "لوحة القيادة",
    icon: ShoppingCartIcon,
    component: OrderForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/sale/edit/:id",
    name: "Edit Sale",
    rtlName: "لوحة القيادة",
    icon: ShoppingCartIcon,
    component: OrderForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketIcon,
    component: Products,
    layout: "/admin"
  },
  {
    path: "/product/create",
    name: "Create Product",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketIcon,
    component: ProductForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/product/edit/:id",
    name: "Edit Product",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketIcon,
    component: ProductForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/product/variations/:id",
    name: "Product Variations",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketIcon,
    component: ProductVariations,
    layout: "/admin",
    hide: true
  },
  {
    path: "/purchases",
    name: "Purchases",
    rtlName: "لوحة القيادة",
    icon: MoveToInboxIcon,
    component: Purchases,
    layout: "/admin"
  },
  {
    path: "/purchase/create",
    name: "Create Purchase",
    rtlName: "لوحة القيادة",
    icon: MoveToInboxIcon,
    component: PurchaseForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/purchase/edit/:id",
    name: "Edit Purchase",
    rtlName: "لوحة القيادة",
    icon: MoveToInboxIcon,
    component: PurchaseForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/grounding",
    name: "Grounding",
    rtlName: "لوحة القيادة",
    icon: PlaylistAddCheckIcon,
    component: Grounding,
    layout: "/admin",
    // hide: true
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "لوحة القيادة",
    icon: AccountBoxIcon,
    component: Customers,
    layout: "/admin"
  },
  {
    path: "/customer/create",
    name: "Create Customer",
    rtlName: "لوحة القيادة",
    icon: AccountBoxIcon,
    component: CustomerForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/customer/edit/:id",
    name: "Edit Customer",
    rtlName: "لوحة القيادة",
    icon: AccountBoxIcon,
    component: CustomerForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    rtlName: "لوحة القيادة",
    icon: ContactPhoneIcon,
    component: Suppliers,
    layout: "/admin",
    hide: true
  },
  {
    path: "/supplier/create",
    name: "Create Supplier",
    rtlName: "لوحة القيادة",
    icon: ContactPhoneIcon,
    component: SupplierForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/supplier/edit/:id",
    name: "Edit Supplier",
    rtlName: "لوحة القيادة",
    icon: ContactPhoneIcon,
    component: SupplierForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/deliveries",
    name: "Deliveries",
    rtlName: "لوحة القيادة",
    icon: DirectionsBikeIcon,
    component: Deliveries,
    layout: "/admin"
  },
  {
    path: "/delivery/create",
    name: "Create Delivery",
    rtlName: "لوحة القيادة",
    icon: DirectionsBikeIcon,
    component: DeliveryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/delivery/edit/:id",
    name: "Edit Delivery",
    rtlName: "لوحة القيادة",
    icon: DirectionsBikeIcon,
    component: DeliveryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/expenses",
    name: "Expenses",
    rtlName: "لوحة القيادة",
    icon: MoneyOffIcon,
    component: Expenses,
    layout: "/admin"
  },
  {
    path: "/expense/create",
    name: "Create Expense",
    rtlName: "لوحة القيادة",
    icon: MoneyOffIcon,
    component: ExpenseForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/expense/edit/:id",
    name: "Edit Expense",
    rtlName: "لوحة القيادة",
    icon: MoneyOffIcon,
    component: ExpenseForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/expense_types",
    name: "Expense Types",
    rtlName: "لوحة القيادة",
    icon: MoneyOffIcon,
    component: ExpenseTypes,
    layout: "/admin"
  },
  {
    path: "/expense_type/create",
    name: "Create Expense Type",
    rtlName: "لوحة القيادة",
    icon: MoneyOffIcon,
    component: ExpenseTypeForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/expense_type/edit/:id",
    name: "Edit Expense Type",
    rtlName: "لوحة القيادة",
    icon: MoneyOffIcon,
    component: ExpenseTypeForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "لوحة القيادة",
    icon: MoneyOffIcon,
    component: Icons,
    layout: "/admin",
    hide: true
  },
  {
    path: "/logout",
    name: "Logout",
    rtlName: "لوحة القيادة",
    icon: ExitToAppIcon,
    component: Logout,
    layout: "/admin",
  },
];

export default dashboardRoutes;
