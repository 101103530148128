import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";

function DeliveryForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [remark, setRemark] = useState('')

    const GetDelivery = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/deliveries/${id}`)
            if (response.ok) {
                setName(response.data.data.name);
                setPhone(response.data.data.phone);
                setEmail(response.data.data.email);
                setAddress(response.data.data.address);
                setRemark(response.data.data.remark);
            }
        }
    }

    useEffect(() => {
        GetDelivery(id);
        return () => {
            setName('');
            setNameError('');
        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        if (name) {
            var response = await fetchData(id ? `api/v1/admin/deliveries/${id}` : 'api/v1/admin/deliveries', { name, phone, email, address, remark }, (id ? 'PUT' : 'POST'));
            if (response.ok) {
                history.push('/deliveries');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Phone"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: phone || '',
                                            onChange: e => setPhone(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Email"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: email || '',
                                            onChange: e => setEmail(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Address"
                                        id="address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: address || '',
                                            onChange: e => setAddress(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Remark"
                                        id="remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: remark || '',
                                            onChange: e => setRemark(e.target.value),
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(DeliveryForm);