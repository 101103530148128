import { Card } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import useFetch, { postFormData, deleteData } from 'components/fetch';
import { getData } from 'components/fetch';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useDidUpdateEffect } from 'components/Hooks/useDidUpdateEffect';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import resolution from 'components/resolution';
import { useScrollToTop } from 'components/ScrollToTop';
import React, { useEffect, useState } from 'react'
import Button from "components/CustomButtons/Button.js";

function MoreBeautyForYou(props) {
    const [more_beauty_for_you_banner_1, setMore_beauty_for_you_banner_1] = useState(null);
    const [more_beauty_for_you_banner_1Error, setMore_beauty_for_you_banner_1Error] = useState('');
    const [more_beauty_for_you_banner_2, setMore_beauty_for_you_banner_2] = useState(null);
    const [more_beauty_for_you_banner_2Error, setMore_beauty_for_you_banner_2Error] = useState('');
    const [more_beauty_for_you_banner_3, setMore_beauty_for_you_banner_3] = useState(null);
    const [more_beauty_for_you_banner_3Error, setMore_beauty_for_you_banner_3Error] = useState('');
    const [more_beauty_for_you_banner_4, setMore_beauty_for_you_banner_4] = useState(null);
    const [more_beauty_for_you_banner_4Error, setMore_beauty_for_you_banner_4Error] = useState('');
    const [link1, setLink1] = useState('');
    const [link2, setLink2] = useState('');
    const [link3, setLink3] = useState('');
    const [link4, setLink4] = useState('');

    const GetBannerImage = async (type) => { // id=brand_id
        var response = await getData(`api/v1/admin/banners?type=${type}`);
        if (response.ok) {
            var img = response.data.data.image.url;
            var url = response.data.data.url;
            switch (type) {
                case 'more_beauty_for_you_banner_1': setMore_beauty_for_you_banner_1(img); setLink1(url); break;
                case 'more_beauty_for_you_banner_2': setMore_beauty_for_you_banner_2(img); setLink2(url); break;
                case 'more_beauty_for_you_banner_3': setMore_beauty_for_you_banner_3(img); setLink3(url); break;
                case 'more_beauty_for_you_banner_4': setMore_beauty_for_you_banner_4(img); setLink4(url); break;
            }
        }
    }

    const Banner1 = async () => { // id=brand_id
        // console.log('b1', more_beauty_for_you_banner_1)
        // if (more_beauty_for_you_banner_1.file) {
        var response = await postFormData(`api/v1/admin/banners`, { type: 'more_beauty_for_you_banner_1', image: more_beauty_for_you_banner_1.file, url: link1 });
        // console.log('b11', response)
        if (response.ok) {
            alert("Image and Link Success");
        }
        // }
    }

    const Banner2 = async () => { // id=brand_id
        // console.log(image)
        // if (more_beauty_for_you_banner_2.file) {
        var response = await postFormData(`api/v1/admin/banners`, { type: 'more_beauty_for_you_banner_2', image: more_beauty_for_you_banner_2.file, url: link2 });
        if (response.ok) {
            alert("Image and Link Success");
        }
        // }
    }
    const Banner3 = async () => { // id=brand_id
        // console.log(image)
        // if (more_beauty_for_you_banner_3.file) {
        var response = await postFormData(`api/v1/admin/banners`, { type: 'more_beauty_for_you_banner_3', image: more_beauty_for_you_banner_3.file, url: link3 });
        if (response.ok) {
            alert("Image and Link Success");
        }
        // }
    }
    const Banner4 = async () => { // id=brand_id
        // console.log(image)
        // if (more_beauty_for_you_banner_4.file) {
        var response = await postFormData(`api/v1/admin/banners`, { type: 'more_beauty_for_you_banner_4', image: more_beauty_for_you_banner_4.file, url: link4 });
        if (response.ok) {
            alert("Image and Link Success");
        }
        // }
    }
    useEffect(() => {
        GetBannerImage('more_beauty_for_you_banner_1');
        GetBannerImage('more_beauty_for_you_banner_2');
        GetBannerImage('more_beauty_for_you_banner_3');
        GetBannerImage('more_beauty_for_you_banner_4');
    }, [])

    // useDidUpdateEffect(() => {
    //     PostBannerImage(more_beauty_for_you_banner_1,link1, 'more_beauty_for_you_banner_1')
    // }, [more_beauty_for_you_banner_1,link1])

    // useDidUpdateEffect(() => {
    //     PostBannerImage(more_beauty_for_you_banner_2,link2, 'more_beauty_for_you_banner_2')
    // }, [more_beauty_for_you_banner_2,link2])

    // useDidUpdateEffect(() => {
    //     PostBannerImage(more_beauty_for_you_banner_3,link3, 'more_beauty_for_you_banner_3')
    // }, [more_beauty_for_you_banner_3,link3])

    // useDidUpdateEffect(() => {
    //     PostBannerImage(more_beauty_for_you_banner_4,link4, 'more_beauty_for_you_banner_4')
    // }, [more_beauty_for_you_banner_4,link4])

    return (
        <div>
            <h5>Banners</h5>
            <GridContainer>
                <GridItem xs={6} sm={6} md={3}>
                    <Card>
                        <CardBody style={{ padding: "20px 0" }}>
                            <GridItem xs={6} sm={3} md={3}>
                                <SingleUpload value={more_beauty_for_you_banner_1} onChange={image => setMore_beauty_for_you_banner_1(image)} dataURLKey="url" errorText={more_beauty_for_you_banner_1Error}
                                    // resolutionProps={resolution.more_beauty_for_you_banner}
                                    style={{ width: 200, height: 366 }}
                                    unremoveable
                                    labelText={`${resolution.more_beauty_for_you_banner.resolutionWidth}x${resolution.more_beauty_for_you_banner.resolutionHeight}`}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                {/* <SingleUpload value={more_beauty_for_you_banner_1} onChange={image => setMore_beauty_for_you_banner_1(image)} dataURLKey="url" errorText={more_beauty_for_you_banner_1Error}  /> */}
                                <CustomInput
                                    labelText="Banner Link 1"
                                    id="link1"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: link1 || '',
                                        onChange: e => setLink1(e.target.value),
                                    }}
                                />
                            </GridItem>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Banner1}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                    <Card>
                        <CardBody style={{ padding: "20px 0" }}>
                            <GridItem xs={6} sm={3} md={3}>
                                <SingleUpload value={more_beauty_for_you_banner_2} onChange={image => setMore_beauty_for_you_banner_2(image)} dataURLKey="url" errorText={more_beauty_for_you_banner_2Error}
                                    // resolutionProps={resolution.more_beauty_for_you_banner}
                                    style={{ width: 200, height: 366 }}
                                    unremoveable
                                    labelText={`${resolution.more_beauty_for_you_banner.resolutionWidth}x${resolution.more_beauty_for_you_banner.resolutionHeight}`}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                {/* <SingleUpload value={more_beauty_for_you_banner_2} onChange={image => setMore_beauty_for_you_banner_2(image)} dataURLKey="url" errorText={more_beauty_for_you_banner_2Error} /> */}
                                <CustomInput
                                    labelText="Banner Link 2"
                                    id="link2"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: link2 || '',
                                        onChange: e => setLink2(e.target.value),
                                    }}
                                />
                            </GridItem>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Banner2}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                    <Card>
                        <CardBody style={{ padding: "20px 0" }}>
                            <GridItem xs={6} sm={3} md={3}>
                                <SingleUpload value={more_beauty_for_you_banner_3} onChange={image => setMore_beauty_for_you_banner_3(image)} dataURLKey="url" errorText={more_beauty_for_you_banner_3Error}
                                    // resolutionProps={resolution.more_beauty_for_you_banner}
                                    style={{ width: 200, height: 366 }}
                                    unremoveable
                                    labelText={`${resolution.more_beauty_for_you_banner.resolutionWidth}x${resolution.more_beauty_for_you_banner.resolutionHeight}`}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                {/* <SingleUpload value={more_beauty_for_you_banner_3} onChange={image => setMore_beauty_for_you_banner_3(image)} dataURLKey="url" errorText={more_beauty_for_you_banner_3Error}  /> */}
                                <CustomInput
                                    labelText="Banner Link 3"
                                    id="link3"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: link3 || '',
                                        onChange: e => setLink3(e.target.value),
                                    }}
                                />
                            </GridItem>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Banner3}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                    <Card>
                        <CardBody style={{ padding: "20px 0" }}>
                            <GridItem xs={6} sm={3} md={3}>
                                <SingleUpload value={more_beauty_for_you_banner_4} onChange={image => setMore_beauty_for_you_banner_4(image)} dataURLKey="url" errorText={more_beauty_for_you_banner_4Error}
                                    // resolutionProps={resolution.more_beauty_for_you_banner}
                                    style={{ width: 200, height: 366 }}
                                    unremoveable
                                    labelText={`${resolution.more_beauty_for_you_banner.resolutionWidth}x${resolution.more_beauty_for_you_banner.resolutionHeight}`}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                {/* <SingleUpload value={more_beauty_for_you_banner_4} onChange={image => setMore_beauty_for_you_banner_4(image)} dataURLKey="url" errorText={more_beauty_for_you_banner_4Error}  /> */}
                                <CustomInput
                                    labelText="Banner Link 4"
                                    id="link4"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: link4 || '',
                                        onChange: e => setLink4(e.target.value),
                                    }}
                                />
                            </GridItem>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Banner4}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default MoreBeautyForYou