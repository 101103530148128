
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    maxHeight: window.innerHeight - 100,
    overflow: 'auto',
    margin: 15,
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CustomModal(props) {
  const classes = useStyles();
  const { modalBodyStyle } = props;
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState({

  });

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.modal}
        style={props.style}
        onBackdropClick={props.onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{ ...modalStyle, ...modalBodyStyle }} className={classes.paper + ' modal'}>
          {props.children}
        </div>
      </Modal>
    </div>
  );
}