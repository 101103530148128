import { useIsLoggedIn } from 'components/Hooks/useIsLoggedIn';
import React, { useEffect, useState } from 'react'
import { Redirect, useLocation, useParams, withRouter } from 'react-router-dom'
import { Card } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { getData } from 'components/fetch';
import { deleteData } from 'components/fetch';
import { postFormData } from 'components/fetch';
import resolution from 'components/resolution';
import ImageUploader, { SingleUpload } from 'components/ImageUploader/ImageUploader';
import { useDidUpdateEffect } from 'components/Hooks/useDidUpdateEffect';
import DealOfADay from 'views/Home/DealOfADay';

const CarouselsAndBanners = ({ history }) => {
    const isLoggedIn = useIsLoggedIn();
    const { id } = useParams();
    const [images, setImages] = useState(null); // carousels
    const [new_arrival_banner, setNew_arrival_banner] = useState(null);
    const [best_seller_banner, setBest_seller_banner] = useState(null);
    const [featured_banner, setFeatured_banner] = useState(null);
    const GetImages = async (id) => {
        var response = await getData(`api/v1/admin/brands/${id}/carousels`);
        if (response.ok) {
            setImages(response.data.data.map(x => ({ ...x, data_url: x.image.url })));
        }
    }
    const DeleteImage = async (id) => {
        var response = await deleteData(`api/v1/admin/carousels/${id}`);
        if (response.ok) {
        }
    }
    const PutImage = async (id, image) => { // id=image_id
        var response = await postFormData(`api/v1/admin/carousels/${id}`, { image });
        if (response.ok) {
        }
    }
    const PostImage = async (id, image) => { // id=brand_id
        var response = await postFormData(`api/v1/admin/brands/${id}/carousels`, { image });
        if (response.ok) {
        }
    }

    const GetBannerImage = async (id, type) => { // id=brand_id
        var response = await getData(`api/v1/admin/brands/${id}/banners/${type}`);
        // console.log(response)
        if (response.ok) {
            var img = response.data.data.image.url;
            switch (type) {
                case 'new_arrival_banner': setNew_arrival_banner(img); break;
                case 'best_seller_banner': setBest_seller_banner(img); break;
                case 'featured_banner': setFeatured_banner(img); break;
            }
        }
    }
    const PostBannerImage = async (id, image, type) => { // id=brand_id
        if (image.file) {
            var response = await postFormData(`api/v1/admin/brands/${id}/banners/${type}`, { image: image.file });
            if (response.ok) {
            }
        }
    }

    useDidUpdateEffect(() => {
        PostBannerImage(id, new_arrival_banner, 'new_arrival_banner')
    }, [new_arrival_banner])

    useDidUpdateEffect(() => {
        PostBannerImage(id, best_seller_banner, 'best_seller_banner')
    }, [best_seller_banner])

    useDidUpdateEffect(() => {
        PostBannerImage(id, featured_banner, 'featured_banner')
    }, [featured_banner])

    useEffect(() => {
        if (id) {
            GetImages(id);
            GetBannerImage(id, 'new_arrival_banner');
            GetBannerImage(id, 'best_seller_banner');
            GetBannerImage(id, 'featured_banner');
        } else {
            setImages(null);
        }
    }, [id])

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem md={12} style={{ width: '100%' }}>
                                <p>Brand Carousel</p>
                                {
                                    images ?
                                        <ImageUploader
                                            resolutionProps={resolution.brandCarousel}
                                            value={images}
                                            onChange={(images, addUpdateIndex, action) => { setImages(images); }}
                                            onImageUpload={(imageList) => {
                                                imageList.map(image => {
                                                    PostImage(id, image.file)
                                                })
                                            }}
                                            onImageUpdate={(imageList) => {
                                                imageList.map(image => {
                                                    PutImage(image.old.image.imageable_id, image.new.file)
                                                })
                                            }}
                                            onImageRemove={(image) => {
                                                if (image.id) {
                                                    DeleteImage(image.image.imageable_id)
                                                }
                                            }}
                                        />
                                        : <div style={{ width: '100%', height: 100 }}><span>Loading...</span></div>
                                }
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p>Banners</p>
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                {
                                    new_arrival_banner ?
                                        <SingleUpload value={new_arrival_banner} onChange={image => setNew_arrival_banner(image)} dataURLKey="url"
                                            resolutionProps={resolution.brand_best_product_banner}
                                            style={{ width: 185, height: 200 }}
                                            unremoveable
                                            labelText={'New Arrival'}
                                        /> :
                                        <SingleUpload value={''} onChange={image => setNew_arrival_banner(image)}
                                            resolutionProps={resolution.brand_best_product_banner}
                                            style={{ width: 185, height: 200 }}
                                            labelText={'New Arrival'}
                                        />
                                }
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                {
                                    best_seller_banner ?
                                        <SingleUpload value={best_seller_banner} onChange={image => setBest_seller_banner(image)} dataURLKey="url"
                                            resolutionProps={resolution.brand_best_product_banner}
                                            style={{ width: 185, height: 200 }}
                                            unremoveable
                                            labelText={'Best Seller'}
                                        /> :
                                        <SingleUpload value={''} onChange={image => setBest_seller_banner(image)}
                                            resolutionProps={resolution.brand_best_product_banner}
                                            style={{ width: 185, height: 200 }}
                                            labelText={'Best Seller'}
                                        />
                                }
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                {
                                    featured_banner ?
                                        <SingleUpload value={featured_banner} onChange={image => setFeatured_banner(image)} dataURLKey="url"
                                            resolutionProps={resolution.brand_best_product_banner}
                                            style={{ width: 185, height: 200 }}
                                            unremoveable
                                            labelText={'Featured'}
                                        /> :
                                        <SingleUpload value={''} onChange={image => setFeatured_banner(image)}
                                            resolutionProps={resolution.brand_best_product_banner}
                                            style={{ width: 185, height: 200 }}
                                            labelText={'Featured'}
                                        />
                                }
                            </GridItem>
                        </GridContainer>
                        <DealOfADay brand_id={id} />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default withRouter(CarouselsAndBanners)