import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { SingleUpload } from "components/ImageUploader/ImageUploader";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { postFormData } from "components/fetch";
import resolution from "components/resolution";

function BrandForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [image, setImage] = useState(null)
    const [imageError, setImageError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [carousel, setCarousel] = useState([])
    const [carouselError, setCarouselError] = useState('')
    const [remark, setRemark] = useState('')

    const GetBrand = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/brands/${id}`)
            if (response.ok) {
                setName(response.data.data.name);
                setImage(response.data.data.image);
            }
        }
    }

    useEffect(() => {
        GetBrand(id);
        return () => {
            setImage(null);
            setImageError('');
            setCarousel([]);
            setCarouselError('');
            setName('');
            setNameError('');
            setRemark('');
        }
    }, [id])

    useDidUpdateEffect(() => {
        setImageError(!image ? 'This field is required' : '')
        return () => {
            setImageError('')
        }
    }, [image])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])

    // useDidUpdateEffect(() => {
    //     setCarouselError(!id && (!carousel || carousel.length <= 0) ? 'This field is required' : '')
    //     return () => {
    //         setCarouselError('')
    //     }
    // }, [carousel])

    const Save = async () => {
        setImageError(!image ? 'This field is required' : '')
        setNameError(!name ? 'This field is required' : '')
        // setCarouselError(!id && (!carousel || carousel.length <= 0) ? 'This field is required' : '')
        if (name && image) {
            var response = await postFormData(id ? `api/v1/admin/brands/${id}` : 'api/v1/admin/brands', { name, image: image.file || image });
            if (response.ok) {
                history.push('/brands');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={2}>
                                    <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="url" errorText={imageError} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                {/* {
                                    !id ?
                                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                                            <ImageUploader
                                                labelText={'Brand Carousel * - Click / Drop here'}
                                                value={carousel}
                                                onChange={(images, addUpdateIndex, action) => { setCarousel(images) }}
                                                errorText={carouselError}
                                            />
                                        </GridItem>
                                        : <></>
                                } */}
                                {/* <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Remark"
                                        id="remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: remark || '',
                                            onChange: e => setRemark(e.target.value)
                                        }}
                                    />
                                </GridItem> */}
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(BrandForm);