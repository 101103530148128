import React, { useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import useFetch from "components/fetch";
import DataTable from "components/Table/DataTable";
import { useScrollToTop } from "components/ScrollToTop";
import { deleteData } from "components/fetch";
import CustomInput from "components/CustomInput/CustomInput";
import { InputLabel } from '@material-ui/core';

function Purchases({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [purchases, setPurchases] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    useScrollToTop(purchases);

    var response = useFetch([page, startDate, endDate], `api/v1/admin/purchases?page=${page}&startDate=${startDate}&endDate=${endDate}`);

    const DeletePurchase = async (id) => {
        var response = await deleteData(`api/v1/admin/purchases/${id}`);
        if (response.ok) {
            setPage(0);
        }
    }
    useEffect(() => {
        if (response && response.ok) {
            setPurchases(response.data.data);
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
        }
    }, [response])

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Date", "Remark", "Action"]}
                            tableData={
                                purchases.map((purchase, index) => ({
                                    data: [purchase.date, purchase.remark],
                                    onClickEdit: () => history.push(`/purchase/edit/${purchase.id}`),
                                    onClickDelete: () => DeletePurchase(purchase.id),
                                    name: purchase.name,
                                }))
                            }
                            onClickCreate={() => history.push(`/purchase/create`)}
                            count={count}
                            page={page}
                            onPaginationChange={async (event, value) => {
                                await setPage(value)
                            }}
                            disableSearch
                            customTableActions={(
                                <>
                                    <GridItem md={4}>
                                        <InputLabel shrink style={{ position: 'relative', top: 45 }}>State Date</InputLabel>
                                        <CustomInput
                                            className='pull-right'
                                            // labelText="State Date"
                                            id="startDate"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: 'date',
                                                value: startDate || '',
                                                onChange: e => setStartDate(e.target.value)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem md={4}>
                                        <InputLabel shrink style={{ position: 'relative', top: 45 }}>End Date</InputLabel>
                                        <CustomInput
                                            className='pull-right'
                                            // labelText="State Date"
                                            id="endDate"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: 'date',
                                                value: endDate || '',
                                                onChange: e => setEndDate(e.target.value)
                                            }}
                                        />
                                    </GridItem>
                                </>
                            )}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
export default withRouter(Purchases);