import React, { useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DataTable from "components/Table/DataTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { deleteData } from "components/fetch";
import { useScrollToTop } from "components/ScrollToTop";
import useFetch from "components/fetch";
import Button from "components/CustomButtons/Button.js";
import { getData } from "components/fetch";
import CustomModal from "components/Modal/CustomModal";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { postData } from "components/fetch";
import { postFormData } from "components/fetch";
import { PhotoLibraryOutlined as PhotoLibraryOutlinedIcon, ViewCarouselOutlined as ViewCarouselOutlinedIcon } from '@material-ui/icons';
import resolution from "components/resolution";

function Products({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [products, setProducts] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [per_page, setPer_page] = useState(1);
    const [search, setSearch] = useState('');
    const [openImageModal, setOpenImageModal] = useState(false);
    const [images, setImages] = useState(null);
    const [selectedProductID, setSelectedProductID] = useState(null);
    useScrollToTop(products);

    var response = useFetch([page, search], `api/v1/admin/products?page=${page}&q=${search}`);
    useEffect(() => {
        if (response && response.ok) {
            setProducts(response.data.data);
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
            setPer_page(response.data.meta.per_page);
        }
    }, [response])

    const DeleteProduct = async (id) => {
        var response = await deleteData(`api/v1/admin/products/${id}`);
        if (response.ok) {
            setPage(0);
        }
    }

    // Images
    const GetImages = async (id) => {
        var response = await getData(`api/v1/admin/products/${id}/images`);
        if (response.ok) {
            setImages(response.data.data.map(x => ({ ...x, data_url: x.url })));
        }
    }
    const DeleteImage = async (id) => {
        var response = await deleteData(`api/v1/admin/images/${id}`);
        if (response.ok) {
        }
    }
    const PutImage = async (id, image) => { // id=image_id
        var response = await postFormData(`api/v1/admin/images/${id}`, { image });
        // console.log('PutImage response', response)
        if (response.ok) {
        }
    }
    const PostImage = async (id, image) => { // id=product_id
        var response = await postFormData(`api/v1/admin/products/${id}/images`, { image });
        // console.log('PostImage response', response)
        if (response.ok) {
        }
    }

    useEffect(() => {
        if (selectedProductID) {
            GetImages(selectedProductID);
        } else {
            setImages(null);
        }
    }, [selectedProductID])

    const handleOpenImageModal = async (id) => {
        setOpenImageModal(true);
        setSelectedProductID(id);
    }
    const handleCloseImageModal = async () => {
        setOpenImageModal(false);
        setSelectedProductID(null);
    }

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Name", 'SKU', 'Brand', 'Qty',"Barcode" , "Action"]}
                            tableData={
                                products.map((product, index) => ({
                                    data: [product.name, product.sku, product.brand ? product.brand.name:null, product.total_stock,product.barcode ? product.barcode:null],
                                    onClickEdit: () => history.push(`/product/edit/${product.id}`),
                                    onClickDelete: () => DeleteProduct(product.id),
                                    name: product.name,
                                    id: product.id,
                                    addtionalActions: (
                                        <>
                                            <Button className='iconbtn' color="info" onClick={() => handleOpenImageModal(product.id)} tooltip={{ title: 'Images' }}><PhotoLibraryOutlinedIcon /></Button>
                                            <Button className='iconbtn' color="success" onClick={() => history.push(`/product/variations/${product.id}`)} tooltip={{ title: 'Variations' }}><ViewCarouselOutlinedIcon /></Button>
                                        </>
                                    )
                                }))
                            }
                            onClickCreate={() => history.push(`/product/create`)}
                            count={count}
                            page={page}
                            onPaginationChange={async (event, value) => {
                                await setPage(value)
                            }}
                            onSearch={setSearch}
                        />
                    </CardBody>
                </Card>
                <CustomModal open={openImageModal} onClose={handleCloseImageModal} style={{ alignItems: 'flex-start', marginTop: 150 }} modalBodyStyle={{ width: 1000, maxWidth: '90%' }}>
                    <GridContainer>
                        <GridItem md={12} style={{ width: '100%' }}>
                            <p>Images</p>
                            <hr />
                            {
                                // console.log("imagesProduct",images),
                                images ?
                                    <ImageUploader value={images}
                                        resolutionProps={resolution.product}
                                        onChange={(images, addUpdateIndex, action) => { setImages(images); }}
                                        onImageUpload={(imageList) => {
                                            imageList.map(image => {
                                                PostImage(selectedProductID, image.file)
                                            })
                                        }}
                                        onImageUpdate={(imageList) => {
                                            imageList.map(image => {
                                                PutImage(image.old.id, image.new.file)
                                            })
                                        }}
                                        onImageRemove={(image) => {
                                            if (image.id) {
                                                DeleteImage(image.id)
                                            }
                                        }}
                                    />
                                    : <div style={{ width: '100%', height: 100 }}><span>Loading...</span></div>
                            }
                        </GridItem>
                    </GridContainer>
                </CustomModal>
            </GridItem>
        </GridContainer>
    );
}

export default withRouter(Products)