import { Card } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import useFetch, { postFormData, deleteData } from 'components/fetch';
import { getData } from 'components/fetch';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useDidUpdateEffect } from 'components/Hooks/useDidUpdateEffect';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import resolution from 'components/resolution';
import { useScrollToTop } from 'components/ScrollToTop';
import React, { useEffect, useState } from 'react'
import Button from "components/CustomButtons/Button.js";

function Banners(props) {
    const [left_home_banner, setLeft_home_banner] = useState(null);
    const [left_home_bannerError, setLeft_home_bannerError] = useState('');
    const [right_home_banner, setRight_home_banner] = useState(null);
    const [right_home_bannerError, setRight_home_bannerError] = useState('');
    const [link, setLink] = useState('');
    const [link2, setLink2] = useState('');

    const GetBannerImage = async (type) => { // id=brand_id
        var response = await getData(`api/v1/admin/banners?type=${type}`);
        // console.log(response)
        if (response.ok) {
            var img = response.data.data.image.url;
            var url = response.data.data.url;
            switch (type) {
                case 'left_home_banner': setLeft_home_banner(img); setLink(url); break;
                case 'right_home_banner': setRight_home_banner(img); setLink2(url); break;
            }
        }
    }
    // const PostBannerImage = async (image, type) => { // id=brand_id
    //     if (image.file) {
    //         var response = await postFormData(`api/v1/admin/banners`, { type, image: image.file });
    //         if (response.ok) {
    //         }
    //     }
    // }

    const PostBannerImage1 = async () => {
        // if (left_home_banner.file) {
        var response = await postFormData(`api/v1/admin/banners`, { type: 'left_home_banner', image: left_home_banner.file, url: link });
        // console.log("banner", response)
        if (response.ok) {
            alert("Image and Link Success");
        }
        // }
    }

    const PostBannerImage2 = async () => {
        // if (right_home_banner.file) {
        var response = await postFormData(`api/v1/admin/banners`, { type: 'right_home_banner', image: right_home_banner.file, url: link2 });
        // console.log("banner2", response)
        if (response.ok) {
            alert("Image and Link Success");
        }
        // }
    }

    useEffect(() => {
        GetBannerImage('left_home_banner');
        GetBannerImage('right_home_banner');
    }, [])

    // useDidUpdateEffect(() => {
    //     PostBannerImage(left_home_banner, 'left_home_banner')
    // }, [left_home_banner])

    // useDidUpdateEffect(() => {
    //     PostBannerImage(right_home_banner, 'right_home_banner')
    // }, [right_home_banner])

    return (
        <div>
            <h5>Banners</h5>
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <Card style={{  }}>
                        <CardBody>
                            <GridItem xs={12} sm={12} md={12}>
                                <SingleUpload value={left_home_banner} onChange={image => setLeft_home_banner(image)} dataURLKey="url" errorText={left_home_bannerError}
                                    // resolutionProps={resolution.home_banner}
                                    style={{ width: 400, height: 168 }}
                                    unremoveable
                                    labelText={`Left Banner (${resolution.home_banner.resolutionWidth}x${resolution.home_banner.resolutionHeight})`}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                {/* <SingleUpload value={left_home_banner} onChange={image => setLeft_home_banner(image)} dataURLKey="url" errorText={left_home_bannerError}  /> */}
                                <CustomInput
                                    labelText="Left Banner Link"
                                    id="link"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: link || '',
                                        onChange: e => setLink(e.target.value),
                                    }}
                                />
                            </GridItem>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={PostBannerImage1}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardBody>
                            <GridItem xs={12} sm={12} md={12}>
                                <SingleUpload value={right_home_banner} onChange={image => setRight_home_banner(image)} dataURLKey="url" errorText={right_home_bannerError}
                                    // resolutionProps={resolution.home_banner}
                                    style={{ width: 400, height: 168 }}
                                    unremoveable
                                    labelText={`Right Banner (${resolution.home_banner.resolutionWidth}x${resolution.home_banner.resolutionHeight})`}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                {/* <SingleUpload value={right_home_banner} onChange={image => setRight_home_banner(image)} dataURLKey="url" errorText={right_home_bannerError} /> */}
                                <CustomInput
                                    labelText="Right Banner Link"
                                    id="link2"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: link2 || '',
                                        onChange: e => setLink2(e.target.value),
                                    }}
                                />
                            </GridItem>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={PostBannerImage2}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default Banners