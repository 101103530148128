import React, { useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import useFetch from "components/fetch";
import DataTable from "components/Table/DataTable";
import { useScrollToTop } from "components/ScrollToTop";
import { deleteData } from "components/fetch";
import CustomInput from "components/CustomInput/CustomInput";
import { InputLabel, Tabs, Tab } from '@material-ui/core';
import { formatMoney } from "components/common";
import { order_type_enum } from "components/enum";
import { order_status_enum } from "components/enum";
import { getData } from "components/fetch";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/Modal/CustomModal";
import { postFormData } from "components/fetch";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { PhotoLibraryOutlined as PhotoLibraryOutlinedIcon, Receipt as ReceiptIcon } from '@material-ui/icons';
import { Lightbox } from "react-modal-image";
import { base64ToArrayBuffer } from "components/pdf";
import { saveByteArray } from "components/pdf";
import { getPDFData } from "components/fetch";
import { API_URL } from "components/common";
import { getToken } from "components/storage";

function OrderLists({ history, order_type }) {
    const isLoggedIn = useIsLoggedIn();
    const [orders, setOrders] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState(order_status_enum.new);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [images, setImages] = useState(null);
    const [selectedProductID, setSelectedProductID] = useState(null);
    const [imageUrl, setImageUrl] = useState(null)
    useScrollToTop(orders);

    const GetOrders = async () => {
        var response = await getData(`api/v1/admin/orders?page=${page}&startDate=${startDate}&endDate=${endDate}&status=${status}&type=${order_type === order_type_enum.checkout ? 'checkout' : 'admin-added'}`);
        if (response.ok) {
            setOrders(response.data.data);
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
        }
    }

    const DeleteOrder = async (id) => {
        var response = await deleteData(`api/v1/admin/orders/${id}`);
        if (response.ok) {
            setPage(0);
        }
    }

    const DownloadOrder = async (id) => {
        var response = await getData(`api/v1/admin/orders/${id}/receipt/download`);
        // console.log("dd", response)
        if (response.ok) {
            setPage(0);
        }
    }

    // Images
    const GetImages = async (id) => {
        var response = await getData(`api/v1/admin/orders/${id}`);
        if (response.ok) {
            setImages(response.data.data.images.map(x => ({ ...x, data_url: x.url })));
        }
    }
    const DeleteImage = async (id) => {
        var response = await deleteData(`api/v1/admin/images/${id}`);
        if (response.ok) {
        }
    }
    const PutImage = async (id, image) => { // id=image_id
        var response = await postFormData(`api/v1/admin/images/${id}`, { image });
        // console.log('PutImage response', response)
        if (response.ok) {
        }
    }

    const PostImage = async (id, image) => { // id=product_id
        var response = await postFormData(`api/v1/admin/orders/${id}/images`, { image });
        // console.log('PostImage response', response)
        if (response.ok) {
        }
    }

    const openImagebox = (img) => {
        setImageUrl(img.url);
    };

    const closeLightbox = () => {
        setImageUrl(null);
    };

    useEffect(() => {
        if (selectedProductID) {
            GetImages(selectedProductID);
        } else {
            setImages(null);
        }
    }, [selectedProductID])

    const downloadReceipt = async (id, name) => {
        var token = await getToken();
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `${API_URL}api/v1/admin/orders/${id}/receipt/download`, true);
        xhr.responseType = 'arraybuffer';
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        xhr.onload = function (e) {
            if (this.status == 200) {
                var blob = new Blob([this.response], { type: "application/pdf" });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Voucher No_" + id + "("+ name +").pdf";
                link.click();
            }
        };
        xhr.send();
        // var response = await getPDFData(`api/v1/admin/orders/${id}/receipt/download`);
        // if (response.ok) {
        //     var sampleArr = base64ToArrayBuffer(response.data);
        //     saveByteArray('Receipt (Voucher No_'+ ').pdf', response.data);
        // }

    }

    const handleOpenImageModal = async (id) => {
        setOpenImageModal(true);
        setSelectedProductID(id);
    }
    const handleCloseImageModal = async () => {
        setOpenImageModal(false);
        setSelectedProductID(null);
    }

    useEffect(() => {
        if (page > 0) {
            GetOrders();
        } else {
            setPage(1);
        }
    }, [page, startDate, endDate])

    useEffect(() => {
        setPage(0);
    }, [status])

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        {
                            order_type === order_type_enum.sale ?
                                <GridContainer style={{ marginRight: 15 }}>
                                    <GridItem md={8}>
                                        <Button color="primary" style={{ marginTop: 30 }} onClick={() => { if (order_type === order_type_enum.sale) history.push({ pathname: `/sale/create`, state: { order_type } }) }}>Create</Button>
                                    </GridItem>
                                </GridContainer>
                                : <></>
                        }
                        <Tabs
                            value={status}
                            onChange={(e, newValue) => setStatus(newValue)}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="New" value={order_status_enum.new} />
                            <Tab label="Confirmed" value={order_status_enum.confirmed} />
                            <Tab label="Delivered" value={order_status_enum.delivered} />
                            <Tab label="Cancelled" value={order_status_enum.canceled} />
                        </Tabs>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={order_type === order_type_enum.checkout ? ["Date", "Order No", "Customer", "Phone", "Total", "Status", "Payment Method", "Account", "Action"] : ["Date", "Order No", "Customer", "Phone", "Total", "Payment Method", "Account", "Action"]}
                            tableData={
                                orders.map((order, index) => {
                                    var total = 0;
                                    for (var i = 0; i < order.order_items.length; i++) {
                                        total += order.order_items[i].price * order.order_items[i].qty;
                                    }
                                    return {
                                        data: order_type === order_type_enum.checkout ?
                                            [order.order_date, order.id, order.name, order.phone, formatMoney(total) + ' MMK', order.status, order.bank_name, order.payment_method ? order.payment_method.acc_name + ' / ' + order.payment_method.acc_no : null] :
                                            [order.order_date, order.id, order.name, order.phone, formatMoney(total) + ' MMK', order.bank_name, order.payment_method ? order.payment_method.acc_name + ' / ' + order.payment_method.acc_no : null],
                                        // onClickDownload: () => DownloadOrder(order.id),
                                        onClickEdit: () => history.push({ pathname: `/${order_type === order_type_enum.sale ? 'sale' : 'order'}/edit/${order.id}`, state: { order_type } }),
                                        onClickDelete: () => { if (order_type === order_type_enum.sale) DeleteOrder(order.id) },
                                        addtionalActions: (
                                            <>
                                                <Button className='iconbtn' color="info" onClick={() => downloadReceipt(order.id, order.name)} tooltip={{ title: 'Receipt' }}><ReceiptIcon /></Button>
                                                <Button className='iconbtn' color="info" onClick={() => handleOpenImageModal(order.id)} tooltip={{ title: 'Images' }}><PhotoLibraryOutlinedIcon /></Button>
                                            </>
                                        ),
                                        disableDelete: order_type === order_type_enum.checkout,
                                        //Download: 'download',
                                        name: order.name,
                                    }
                                })
                            }
                            count={count}
                            page={page}
                            onPaginationChange={async (event, value) => {
                                await setPage(value)
                            }}
                            disableSearch
                            disableCreate
                            customTableActions={(
                                <>
                                    <GridItem md={4}>
                                        <InputLabel shrink style={{ position: 'relative', top: 45 }}>State Date</InputLabel>
                                        <CustomInput
                                            className='pull-right'
                                            // labelText="State Date"
                                            id="startDate"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: 'date',
                                                value: startDate || '',
                                                onChange: e => setStartDate(e.target.value)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem md={4}>
                                        <InputLabel shrink style={{ position: 'relative', top: 45 }}>End Date</InputLabel>
                                        <CustomInput
                                            className='pull-right'
                                            // labelText="State Date"
                                            id="endDate"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: 'date',
                                                value: endDate || '',
                                                onChange: e => setEndDate(e.target.value)
                                            }}
                                        />
                                    </GridItem>
                                </>
                            )}
                        />
                    </CardBody>
                </Card>
                <CustomModal open={openImageModal} onClose={handleCloseImageModal} style={{ alignItems: 'flex-start', marginTop: 150 }} modalBodyStyle={{ width: 1000, maxWidth: '90%' }}>
                    <GridContainer>
                        <GridItem md={12} style={{ width: '100%' }}>
                            <p>Images</p>
                            <hr />
                            {
                                // console.log("imagesProduct",images),
                                images ?
                                    <ImageUploader value={images}
                                        onChange={(images, addUpdateIndex, action) => { setImages(images); }}
                                        onViewImage={(image) => { openImagebox(image) }}
                                        onImageUpload={(imageList) => {
                                            imageList.map(image => {
                                                PostImage(selectedProductID, image.file)
                                            })
                                        }}
                                        onImageUpdate={(imageList) => {
                                            imageList.map(image => {
                                                PutImage(image.old.id, image.new.file)
                                            })
                                        }}
                                        onImageRemove={(image) => {
                                            if (image.id) {
                                                DeleteImage(image.id)
                                            }
                                        }}
                                    />
                                    : <div style={{ width: '100%', height: 100 }}><span>Loading...</span></div>
                            }
                            {
                                imageUrl ?
                                    <Lightbox
                                        large={imageUrl}
                                        alt="Image"
                                        onClose={closeLightbox}
                                    />
                                    :
                                    <></>
                            }
                        </GridItem>
                    </GridContainer>
                </CustomModal>
            </GridItem>
        </GridContainer>
    );
}

export const Orders = withRouter((props) => {
    return <OrderLists {...props} order_type={order_type_enum.checkout} />
})
export const Sales = withRouter((props) => {
    return <OrderLists {...props} order_type={order_type_enum.sale} />
})
export default withRouter(OrderLists);