import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData } from "components/fetch";
import { fetchData } from "components/fetch";
import { CustomPaginateSelect } from "components/CustomSelect/CustomSelect";
import CustomAutoComplete from "components/CustomAutoComplete/CustomAutoComplete";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";

function PaymentMethodForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [no, setNo] = useState('')
    const [noError, setNoError] = useState('')
    const [bank, setBank] = useState(undefined)
    const [bankError, setBankError] = useState('')

    const GetPaymentMethod = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/payment_methods/${id}`)
            if (response.ok) {
                var { name, bank_id, no , bank } = response.data.data;
                setName(name);
                setNo(no);
                setBank({ label: bank.name, value: bank.value });
            }
        }
    }

    useEffect(() => {
        GetPaymentMethod(id);
        return () => {
            setName('');
            setNameError('');
            setNo('');
            setNoError('');
            setBank(undefined);
            setBankError('');
        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])

    useDidUpdateEffect(() => {
        setNoError(!no ? 'This field is required' : '')
        return () => {
            setNoError('')
        }
    }, [no])

    useDidUpdateEffect(() => {
        setBankError(!bank ? 'This field is required' : '')
        return () => {
            setBankError('')
        }
    }, [bank])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        setNoError(!no ? 'This field is required' : '')
        setBankError(!bank ? 'This field is required' : '')
        if (name && no && bank) {
            var response = await fetchData(id ? `api/v1/admin/payment_methods/${id}` : 'api/v1/admin/payment_methods', { acc_name: name, acc_no: no , bank_id: bank?.value }, (id ? 'PUT' : 'POST'));
            if (response.ok) {
                history.push('/payment_methods');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Account Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText={'Account No *'}
                                        id="no"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'number',
                                            value: no,
                                            onChange: e => setNo(e.target.value),
                                            onBlur: e => setNoError(!no ? 'This field is required' : '')
                                        }}
                                        errorText={noError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomAutoComplete
                                        labelText="Bank *"
                                        id="bank_id"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={bank}
                                        onChange={(event, value) => setBank(value)}
                                        onBlur={() => setBankError(!bank ? 'This field is required' : '')}
                                        url={`api/v1/admin/banks`}
                                        labelField='name'
                                        valueField='id'
                                        errorText={bankError}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(PaymentMethodForm);