import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "components/CustomButtons/Button.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import CustomModal from "components/Modal/CustomModal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Pagination from '@material-ui/lab/Pagination';
import CustomInput from "components/CustomInput/CustomInput";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { TableFooter } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles(styles);

export default function DataTable(props) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [deleteFnc, setDeleteFnc] = useState(undefined);
  const [nameToDelete, setNameToDelete] = useState('');
  const { tableHead, tableData, tableHeaderColor, tableFooterData } = props;

  const handleCloseModal = () => {
    setOpen(false);
    setDeleteFnc(undefined);
  }

  useDidUpdateEffect(() => {
    if (props.onSearch) {
      props.onSearch(search)
    }
    return () => {
      setSearch('')
    }
  }, [search])

  const TableWrapper = (tableProps) =>
    <>
      {
        props.stickyHeader ?
          <Table stickyHeader className={classes.table}>
            {tableProps.children}
          </Table> :
          <Table className={classes.table}>
            {tableProps.children}
          </Table>
      }
    </>
  return (
    <div className={classes.tableResponsive} style={props.style}>
      <GridContainer style={{ marginRight: 15 }}>
        {
          !props.disableCreate ? (
            <GridItem md={8}>
              <Button color="primary" style={{ marginTop: 30 }} onClick={props.onClickCreate}>Create</Button>
            </GridItem>
          ) : <></>
        }
        {
          !props.disableSearch ? (
            <GridItem md={4}>
              <CustomInput
                className='pull-right'
                labelText="Search"
                id="search"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: search || '',
                  onChange: e => setSearch(e.target.value)
                }}
              />
            </GridItem>
          ) : <></>
        }
        {
          props?.customActions || <></>
        }
      </GridContainer>
      <TableWrapper>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={key} className={classes.tableBodyRow} selected={prop.isSelected}>
                {prop.data.map((prop, key) => {
                  return (
                    <TableCell className={classes.tableCell} key={key}>
                      {prop}
                    </TableCell>
                  );
                })}
                {
                  prop.customActions ? (
                    <TableCell className={classes.tableCell} key={key}>
                      {prop.customActions}
                    </TableCell>
                  ) : (
                      <TableCell className={classes.tableCell} key={key}>
                        {
                          prop.addtionalActions ? prop.addtionalActions : <></>
                        }
                        {
                          prop.Download ? (
                            <Button className='iconbtn' color="success" onClick={prop.onClickDownload} tooltip={{ title: 'Download' }}><CloudDownloadIcon /></Button>
                          ) : (<></>)
                        }
                        {
                          !prop.disableEdit ? (
                            <Button className='iconbtn' color="warning" onClick={prop.onClickEdit} tooltip={{ title: 'Edit' }}><EditIcon /></Button>
                          ) : (<></>)
                        }
                        {
                          !prop.disableDelete ? (
                            <Button className='iconbtn' color="danger" onClick={() => { setOpen(true); setNameToDelete(prop.name); setDeleteFnc(() => prop.onClickDelete) }} tooltip={{ title: 'Delete' }}><DeleteIcon /></Button>
                          ) : (<></>)
                        }
                      </TableCell>
                    )
                }
              </TableRow>
            );
          })}
        </TableBody>
        {
          tableFooterData && tableFooterData.length > 0 ?
            <TableFooter>
              {tableFooterData.map((prop, key) => {
                return (
                  <TableRow key={key} className={classes.tableBodyRow}>
                    {prop.data.map((prop, key) => {
                      return (
                        <TableCell className={classes.tableCell} key={key}>
                          {prop}
                        </TableCell>
                      );
                    })}
                    <TableCell className={classes.tableCell}>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableFooter> : <></>
        }
      </TableWrapper>
      {
        !props.disablePagination ?
          <div className='pull-right' style={{ padding: 15 }}>
            <Pagination count={props.count} page={props.page} onChange={async (event, value) => {
              await props.onPaginationChange(event, value);
            }} />
          </div>
          : <></>
      }
      <CustomModal open={open} onClose={handleCloseModal} style={{ alignItems: 'flex-start', marginTop: 150 }}>
        <GridContainer>
          <GridItem md={12} style={{ width: '100%' }}>
            <p>Are you sure you want to delete <b><font color={'red'}> {nameToDelete} </font></b>?</p>
            <hr />
            <div className='pull-right'>
              <Button color="info" onClick={handleCloseModal}>Cancel</Button>
              <Button color="danger" onClick={() => { if (deleteFnc) deleteFnc(); handleCloseModal(); }}>Confirm</Button>
            </div>
          </GridItem>
        </GridContainer>
      </CustomModal>
    </div>
  );
}

DataTable.defaultProps = {
  tableHeaderColor: "gray"
};

DataTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object)
};
