import React, { useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DataTable from "components/Table/DataTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { useScrollToTop } from "components/ScrollToTop";
import useFetch, { deleteData } from "components/fetch";
import CustomModal from "components/Modal/CustomModal";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
import { fetchData } from "components/fetch";
import { getData } from "components/fetch";
import { Array } from 'react-native-trifinity';
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader";
import { SingleUpload } from "components/ImageUploader/ImageUploader";
import { postFormData } from "components/fetch";
import { iterationCopy } from 'components/common';
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import ImageUploader from "components/ImageUploader/ImageUploader";
import resolution from "components/resolution";
import { PhotoLibraryOutlined as PhotoLibraryOutlinedIcon, ViewCarouselOutlined as ViewCarouselOutlinedIcon } from '@material-ui/icons';
import { postData } from "components/fetch";

function ProductVariations({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    const [variations, setVariations] = useState([]);
    const [variationColorError, setVariationColorError] = useState('');
    const [variationBarcodeError, setVariationBarcodeError] = useState('');
    const [variationPriceError, setVariationPriceError] = useState('');
    const [sizeNameError, setSizeNameError] = useState('');
    const [sizePriceError, setSizePriceError] = useState('');
    const [sizeBarcodeError, setSizeBarcodeError] = useState('');
    const [refreshCount, setRefreshCount] = useState(0);
    const [openVariationModal, setOpenVariationModal] = useState(false);

    // Variation Form
    const [selectedVariationID, setSelectedVariationID] = useState(null);
    const [variation, setVariation] = useState(null);
    const [size, setSize] = useState(null);
    const [variationImages, setVariationImages] = useState([])
    const [variationImagesError, setVariationImagesError] = useState('')
    const [images, setImages] = useState([])
    const [sizeImages, setSizeImages] = useState(null)
    const [sizeImagesError, setSizeImagesError] = useState('');
    const [openImageModal, setOpenImageModal] = useState(false);
    const [selectedSizeID, setSelectedSizeID] = useState(null);
    const [selectedSizeImageID, setSelectedSizeImageID] = useState(null);
    useScrollToTop(variations);

    var response = useFetch([refreshCount], `api/v1/admin/products/${id}/variations`);
    useEffect(() => {
        if (response && response.ok) {
            setVariations(response.data.data);
        }
    }, [response])

    const DeleteVariation = async (id) => {
        var response = await deleteData(`api/v1/admin/variations/${id}`);
        if (response.ok) {
            setRefreshCount(count => count + 1);
        }
    }
    const DeleteSize = async (id) => {
        var response = await deleteData(`api/v1/admin/sizes/${id}`);
        if (response.ok) {
            GetVariation(selectedVariationID);
            setSelectedSizeID(null);
        }
    }

    const GetVariation = async (id) => {
        var response = await getData(`api/v1/admin/variations/${id}`);
        if (response.ok) {
            setVariation(response.data.data);
        }
    }
    
    useEffect(() => {
        if (selectedVariationID) {
            GetVariation(selectedVariationID);
        } else {
            setVariation(null);
            setSelectedSizeID(null);
        }
    }, [selectedVariationID])

    
    // Images
    // const GetVariationImages = async (id) => {
    //     var response = await getData(`api/v1/admin/variations/${id}/images`);
    //     // console.log('dfdfd',response);
    //     if (response.ok) {
    //         setVariationImages(response.data.data.map(x => ({ ...x, data_url: x.url })));
    //     }
    // }
    const GetSizeImages = async (id) => {
        var response = await getData(`api/v1/admin/sizes/${id}/images`);
        if (response.ok) {
            setSizeImages(response.data.data.map(x => ({ ...x, data_url: x.url })));
        }
    }
    const DeleteImage = async (id) => {
        var response = await deleteData(`api/v1/admin/images/${id}`);
        if (response.ok) {
        }
    }
    const PutImage = async (id, image) => { // id=image_id
        var response = await postFormData(`api/v1/admin/images/${id}`, { image });
        if (response.ok) {
        }
    }
    // const PostVariationImage = async (id, image) => { // id=product_id
    //     var response = await postFormData(`api/v1/admin/variations/${id}/images`, { image });
    //     if (response.ok) {
    //     }
    // }
    const PostSizeImage = async (id, image) => { // id=product_id
        var response = await postFormData(`api/v1/admin/sizes/${id}/images`, { image });
        if (response.ok) {
        }
    }

    const handleOpenImageModal = async (id) => {
        setOpenImageModal(true);
        setSelectedVariationID(id);
    }
    const handleOpenSizeImageModal = async (id) => {
        setOpenImageModal(true);
        setSelectedSizeImageID(id);
    }
    const handleCloseImageModal = async () => {
        setOpenImageModal(false);
        setSelectedSizeImageID(null);
    }

    useEffect(() => {
        if (selectedSizeID) {
            var size = variation?.sizes.find(x => x.id === selectedSizeID);
            if (size) {
                setSize(Object.assign({}, size));
            } else {
                setSelectedSizeID(null);
                alert("Something's wrong with fetching size data. Please try again.");
            }
        } else {
            setSize(null);
            setImages([]);
        }
    }, [selectedSizeID])

    const handleOpenVariationModal = (id) => {
        setOpenVariationModal(true);
        setSelectedVariationID(id);
    }

    const handleCloseVariationModal = () => {
        setOpenVariationModal(false);
        setSelectedVariationID(null);
        setRefreshCount(count => count + 1);
    }

    const handleInputVariation = (key, value) => {
        var v = variation || {};
        v[key] = value;
        setVariation(Object.assign({}, v));
    }
    const handleInputSize = (key, value) => {
        var s = size || {};
        s[key] = value;
        setSize(Object.assign({}, s));
    }

    useDidUpdateEffect(() => {
        setVariationColorError(variation?.color ? '' : 'This field is required')
    }, [variation?.color])

    useEffect(() => {
        if (selectedSizeImageID) {
            GetSizeImages(selectedSizeImageID);
        } else {
            setSizeImages(null);
        }
    }, [selectedSizeImageID])

    // useDidUpdateEffect(() => {
    //     if (selectedVariationID) {
    //         GetVariationImages(selectedVariationID);
    //     } else {
    //         setVariationImages(null);
    //         setSelectedSizeID(null);
    //     }
    // }, [selectedVariationID])

    // useDidUpdateEffect(() => {
    //     setVariationBarcodeError(variation?.barcode ? '' : 'This field is required')
    // }, [variation?.barcode])

    useDidUpdateEffect(() => {
        setVariationPriceError(variation?.price ? '' : 'This field is required')
    }, [variation?.price])

    // useDidUpdateEffect(() => {
    //     setSizeImagesError(size?.sizeImages ? '' : 'This field is required')
    // }, [size?.sizeImages])

    useDidUpdateEffect(() => {
        setSizeNameError(size?.name ? '' : 'This field is required')
    }, [size?.name])

    useDidUpdateEffect(() => {
        setSizePriceError(size?.price ? '' : 'This field is required')
    }, [size?.price])

    useDidUpdateEffect(() => {
        setSizeBarcodeError(size?.barcode ? '' : 'This field is required')
    }, [size?.barcode])

    const SaveVariation = async () => {
        const { color,barcode, price = 0, original_price , product_id } = variation || {};
        setVariationColorError(color ? '' : 'This field is required')
        // setVariationBarcodeError(barcode ? '' : 'This field is required')
        setVariationPriceError(price ? '' : 'This field is required')
        // setVariationImagesError((!variationImages || variationImages.length <= 0) ? 'This field is required' : '')
        if (variation && color) {
            var data = { color, barcode , price, original_price , product_id };
            if (!data['stock'] && !selectedVariationID) { // create
                data['stock'] = 0;
            }
            if (!data['product_id']) {
                data['product_id'] = id;
            }
            if (!data['original_price']) {
                data['original_price'] = '';
            }
            // if (variationImages && variationImages.length > 0) {
            //     data = { ...data, images: variationImages.map(x => x.file), stock: 0, new: 1 };
            //     var response = await postFormData(selectedVariationID ? `api/v1/admin/variations/${selectedVariationID}` : 'api/v1/admin/variations', data);
            //     if (response.ok) {
            //         handleCloseVariationModal();
            //     }
            // } else {
                var response = await postFormData(selectedVariationID ? `api/v1/admin/variations/${selectedVariationID}` : 'api/v1/admin/variations', data);
                if (response.ok) {
                    handleCloseVariationModal();
                }
            // }
        }
    }

    const SaveSize = async () => {
        const { barcode, name, price,original_price, variation_id } = size || {};
        setSizeNameError(name ? '' : 'This field is required')
        setSizeBarcodeError(barcode ? '' : 'This field is required')
        setSizePriceError(price ? '' : 'This field is required')
        if (size && name && price && barcode ) {
            var data = { barcode, name, price, original_price , variation_id ,images: images.map(x => x.file)};
            if (!data['stock'] && !selectedSizeID) {
                data['stock'] = 0;
            }
            if (!data['variation_id']) {
                data['variation_id'] = selectedVariationID;
            }
            if (!data['original_price']) {
                data['original_price'] = '';
            }
            // if (images && images.length > 0) {
            //     data = { ...data, images: images.map(x => x.file), stock: 0, new: 1 };
                var response = await postFormData(selectedSizeID ? `api/v1/admin/sizes/${selectedSizeID}` : 'api/v1/admin/sizes', data);
                // console.log("simg",original_price,'d',response)
                if (response.ok) {
                    setSelectedSizeID(null);
                    setSize(null);
                    setImages([]);
                    GetVariation(selectedVariationID);
                }
            // } else {
            //     var response = await postFormData(selectedSizeID ? `api/v1/admin/sizes/${selectedSizeID}` : 'api/v1/admin/sizes', data);
            //     // console.log("simg",response)
            //     if (response.ok) {
            //         setSelectedSizeID(null);
            //         setSize(null);
            //         setImages([]);
            //         GetVariation(selectedVariationID);
            //     }
            // }
        }
    }

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Variation", "Qty", "Action"]}
                            tableData={
                                variations.map((variation, index) => ({
                                    data: [variation.color, variation.total_stock],
                                    onClickEdit: () => handleOpenVariationModal(variation.id),
                                    onClickDelete: () => DeleteVariation(variation.id),
                                    name: variation.color,
                                    // id: variation.id,
                                    // addtionalActions: (
                                    //     <>
                                    //         <Button className='iconbtn' color="info" onClick={() => handleOpenImageModal(variation.id)} tooltip={{ title: 'Images' }}><PhotoLibraryOutlinedIcon /></Button>
                                    //     </>
                                    // )
                                }))
                            }
                            disablePagination
                            disableSearch
                            onClickCreate={() => handleOpenVariationModal()}
                        // count={count}
                        // page={page}
                        // onPaginationChange={async (event, value) => {
                        //     await setPage(value)
                        // }}
                        // onSearch={setSearch}
                        />
                    </CardBody>
                </Card>
                <CustomModal open={openVariationModal} onClose={handleCloseVariationModal} style={{ alignItems: 'flex-start', marginTop: selectedVariationID ? 0 : 150 }} modalBodyStyle={{ width: 1000, maxWidth: '90%' }}>
                    <GridContainer>
                        <GridItem md={12} style={{ width: '100%' }}>
                            <p>{selectedVariationID ? 'Edit' : 'Create'} Variation</p>
                            <hr />
                            <GridContainer>
                                {/* {
                                    !selectedVariationID ?
                                    <GridItem xs={12} sm={12} md={12}>
                                        <ImageUploader
                                            labelText={'Variations Images * - Click / Drop here'}
                                            value={variationImages}
                                            onChange={(variationImages, addUpdateIndex, action) => {  setVariationImages(variationImages) }}
                                            // errorText={variationImagesError}
                                            resolutionProps={resolution.product}
                                        />
                                    </GridItem>
                                    :
                                    <></>
                                } */}
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={5}>
                                            <CustomInput
                                                labelText="Variation"
                                                id="color"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: variation?.color || '',
                                                    onChange: e => handleInputVariation('color', e.target.value),
                                                    onBlur: e => setVariationColorError(variation?.color ? '' : 'This field is required')
                                                }}
                                                errorText={variationColorError}
                                            />
                                        </GridItem>
                                        {/* <GridItem xs={12} sm={12} md={5}>
                                            <CustomInput
                                                labelText="Barcode"
                                                id="barcode"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: variation ?.barcode || '',
                                                    onChange: e => handleInputVariation('barcode',e.target.value),
                                                    onBlur: e => setVariationBarcodeError(variation?.barcode ? '':'This field is required' )
                                                }}
                                                errorText={variationBarcodeError}
                                            />
                                        </GridItem> */}
                                            {/* <CustomInput
                                                labelText="Price"
                                                id="price"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: 'number',
                                                    value: variation?.price || '',
                                                    onChange: e => handleInputVariation('price', e.target.value),
                                                    onBlur: e => setVariationPriceError(variation?.price ? '' : 'This field is required')
                                                }}
                                                errorText={variationPriceError}
                                            /> */}
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Button color="primary" onClick={SaveVariation}>Save</Button>
                                        </GridItem>
                                        {
                                            selectedVariationID ? (
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Card>
                                                        <CardHeader style={{ paddingBottom: 0, height: 25 }}>
                                                            <p>Sizes</p>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <GridContainer>
                                                                {
                                                                    !selectedSizeID ?
                                                                        <GridItem xs={12} sm={12} md={12}>
                                                                            <ImageUploader
                                                                                labelText={'Sizes Images * - Click / Drop here'}
                                                                                value={images}
                                                                                onChange={(images, addUpdateIndex, action) => {  setImages(images) }}
                                                                                // errorText={sizeImagesError}
                                                                                resolutionProps={resolution.product}
                                                                            />
                                                                        </GridItem>
                                                                        :
                                                                        <></>
                                                                }
                                                                {/* <GridItem xs={12} sm={12} md={2}>
                                                                    <SingleUpload value={size?.image} onChange={image => handleInputSize('image', image)} dataURLKey="url" errorText={sizeImageError} />
                                                                </GridItem> */}
                                                                <GridItem xs={12} sm={12} md={3}>
                                                                    <CustomInput
                                                                        labelText="Name"
                                                                        id="name"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            value: size?.name || '',
                                                                            onChange: e => handleInputSize('name', e.target.value),
                                                                            onBlur: e => setSizeNameError(size?.name ? '' : 'This field is required')
                                                                        }}
                                                                        errorText={sizeNameError}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={3}>
                                                                    <CustomInput
                                                                        labelText="Price"
                                                                        id="price"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            type: 'number',
                                                                            value: size?.price || '',
                                                                            onChange: e => handleInputSize('price', e.target.value),
                                                                            onBlur: e => setSizePriceError(size?.price ? '' : 'This field is required')
                                                                        }}
                                                                        errorText={sizePriceError}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={3}>
                                                                    <CustomInput
                                                                        labelText="Original Price"
                                                                        id="original_price"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            type: 'number',
                                                                            value: size?.original_price || '',
                                                                            onChange: e => handleInputSize('original_price', e.target.value),
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={3}>
                                                                    <CustomInput
                                                                        labelText="Barcode"
                                                                        id="barcode"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            value: size?.barcode || '',
                                                                            onChange: e => handleInputSize('barcode',e.target.value),
                                                                            onBlur: e => setSizeBarcodeError(size?.barcode ? '':'This field is required' )
                                                                        }}
                                                                        errorText={sizeBarcodeError}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={3} style={{
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    {
                                                                        selectedSizeID ?
                                                                            <>
                                                                                <Button color="success" onClick={SaveSize}>Edit Size</Button>
                                                                                <Button color="warning" onClick={() => setSelectedSizeID(null)}>Cancel</Button>
                                                                            </> 
                                                                            :
                                                                            <Button color="success" onClick={SaveSize}>Add Size</Button>
                                                                    }
                                                                </GridItem>
                                                            </GridContainer>
                                                            <DataTable
                                                                style={{
                                                                    height: 300,
                                                                    overflow: 'auto'
                                                                }}
                                                                tableHeaderColor="primary"
                                                                tableHead={["Name", "Stock", "Price","Original Price", 'Barcode','Action']}
                                                                tableData={
                                                                    variation?.sizes.map((size, index) => ({
                                                                        data: [size.name, size.stock, size.price,size.original_price,size.barcode],
                                                                        onClickEdit: () => setSelectedSizeID(size.id),
                                                                        onClickDelete: () => DeleteSize(size.id),
                                                                        name: size.name,
                                                                        // isSelected: size.id === selectedSizeID,
                                                                        id: size.id,
                                                                        addtionalActions: (
                                                                            <>
                                                                                <Button className='iconbtn' color="info" onClick={() => handleOpenSizeImageModal(size.id)} tooltip={{ title: 'Images' }}><PhotoLibraryOutlinedIcon /></Button>
                                                                            </>
                                                                        )
                                                                    })) || []
                                                                }
                                                                // stickyHeader
                                                                disablePagination
                                                                disableSearch
                                                                disableCreate
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>
                                            ) : <></>
                                        }
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CustomModal>
                <CustomModal open={openImageModal} onClose={handleCloseImageModal} style={{ alignItems: 'flex-start', marginTop: 150 }} modalBodyStyle={{ width: 1000, maxWidth: '90%' }}>
                    <GridContainer>
                        <GridItem md={12} style={{ width: '100%' }}>
                            <p>Images</p>
                            <hr />
                            {
                                // variationImages ?
                                //     <ImageUploader value={variationImages}
                                //         resolutionProps={resolution.product}
                                //         onChange={(variationImages, addUpdateIndex, action) => { setVariationImages(variationImages); }}
                                //         onImageUpload={(imageList) => {
                                //             imageList.map(image => {
                                //                 PostVariationImage(selectedVariationID, image.file)
                                //             })
                                //         }}
                                //         onImageUpdate={(imageList) => {
                                //             imageList.map(image => {
                                //                 PutImage(image.old.id, image.new.file)
                                //             })
                                //         }}
                                //         onImageRemove={(image) => {
                                //             if (image.id) {
                                //                 DeleteImage(image.id)
                                //             }
                                //         }}
                                //     />
                                //     : 
                                    sizeImages ?
                                    <ImageUploader value={sizeImages}
                                        resolutionProps={resolution.product}
                                        onChange={(sizeImages, addUpdateIndex, action) => { setSizeImages(sizeImages); }}
                                        onImageUpload={(imageList) => {
                                            imageList.map(image => {
                                                PostSizeImage(selectedSizeImageID, image.file)
                                            })
                                        }}
                                        onImageUpdate={(imageList) => {
                                            imageList.map(image => {
                                                PutImage(image.old.id, image.new.file)
                                            })
                                        }}
                                        onImageRemove={(image) => {
                                            if (image.id) {
                                                DeleteImage(image.id)
                                            }
                                        }}
                                    />
                                    :                                    
                                    <div style={{ width: '100%', height: 100 }}><span>Loading...</span></div>
                            }
                        </GridItem>
                    </GridContainer>
                </CustomModal>
            </GridItem>
        </GridContainer>
    );
}

export default withRouter(ProductVariations)