import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData } from "components/fetch";
import { fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import moment from 'moment';
import CustomAutoComplete from "components/CustomAutoComplete/CustomAutoComplete";
import { useCallback } from "react";
import DataTable from "components/Table/DataTable";
import { Delete, Delete as DeleteIcon } from "@material-ui/icons";
import { Array as ArrayFnc } from 'react-native-trifinity';
import { InputLabel } from '@material-ui/core';

function PurchaseForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
    const [dateError, setDateError] = useState('')
    const [remark, setRemark] = useState('')
    const [product, setProduct] = useState(null)
    const [productError, setProductError] = useState('')
    const [variations, setVariations] = useState([])
    const [variation, setVariation] = useState(null)
    const [variationError, setVariationError] = useState('')
    const [sizes, setSizes] = useState([])
    const [size, setSize] = useState(null)
    const [sizeError, setSizeError] = useState('')
    const [qty, setQty] = useState(0)
    const [qtyError, setQtyError] = useState('')
    const [purchasedProducts, setPurchasedProducts] = useState([])

    const GetPurchase = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/purchases/${id}`)
            if (response.ok) {
                const { date, purchase_items, remark } = response.data.data;
                setDate(date);
                setPurchasedProducts(purchase_items.map(x => ({
                    type: x.type,
                    qty: x.qty,
                    // purchasable_id: x.purchasable_id,
                    id: x.id,
                    product: x.type === 'size' ? x.purchasable_item.variation.product.name : x.type === 'variation' ? x.purchasable_item.product.name : x.purchasable_item.name,
                    variation: x.type === 'size' ? x.purchasable_item.variation.color : x.type === 'variation' ? x.purchasable_item.color : '',
                    size: x.type === 'size' ? x.purchasable_item.name : '',
                })))
                setRemark(remark);
            }
        }
    }

    const GetVariations = async (product_id) => {
        if (product_id) {
            var response = await getData(`api/v1/admin/products/${product_id}/variations`)
            if (response.ok) {
                setVariations(response.data.data.map(x => ({ label: x.color, value: x.id })));
            }
        }
    }

    const GetSizes = async (variation_id) => {
        if (variation_id) {
            var response = await getData(`api/v1/admin/variations/${variation_id}`)
            if (response.ok) {
                setSizes(response.data.data.sizes.map(x => ({ label: x.name, value: x.id })));
            }
        }
    }

    useEffect(() => {
        GetPurchase(id);
        return () => {
            setDate('');
            setDateError('');
            setRemark('');
        }
    }, [id])

    useEffect(() => {
        if (product) {
            GetVariations(product.value)
        }
        setVariations([]);
        setVariation(null);
        setSizes([]);
        setSize(null);
    }, [product])

    useEffect(() => {
        if (variation) {
            GetSizes(variation.value)
        }
        setSizes([]);
        setSize(null);
    }, [variation])

    // useEffect(() => {
    //     console.log(variations)
    // }, [variations])

    useDidUpdateEffect(() => {
        setDateError(!date ? 'This field is required' : '')
        return () => {
            setDateError('')
        }
    }, [date])

    useDidUpdateEffect(() => {
        setQtyError(!qty ? 'This field is required' : '')
        return () => {
            setQtyError('')
        }
    }, [qty])

    useDidUpdateEffect(() => {
        setProductError(!product ? 'This field is required' : '')
        return () => {
            setProductError('')
        }
    }, [product])

    const Add = async () => {
        setProductError(!product ? 'This field is required' : '');
        setVariationError((variations.length > 0 && !variation) ? 'This field is required' : '');
        setSizeError((sizes.length > 0 && !size) ? 'This field is required' : '');
        setQtyError((!qty || qty <= 0) ? 'This field is required' : '');
        if (product && product.value && (variations.length <= 0 || (variation && variation.value)) && (sizes.length <= 0 || (size && size.value)) && qty) {
            var purchasedProduct = {
                type: size ? 'size' : variation ? 'variation' : 'product',
                qty: qty,
                purchasable_id: size ? size.value : variation ? variation.value : product.value,
                product: product.label,
                variation: variation?.label || '',
                size: size?.label || '',
            };
            purchasedProducts.push(purchasedProduct);
            setPurchasedProducts(ArrayFnc.clone(purchasedProducts));
            setProduct(null);
            setVariations([]);
            setVariation(null);
            setSizes([]);
            setSize(null);
            setQty();
        }
    }

    const Delete = (index) => {
        ArrayFnc.arrayRemoveByIndex(purchasedProducts, index);
        setPurchasedProducts(ArrayFnc.clone(purchasedProducts));
    }

    const Save = async () => {
        setDateError(!date ? 'This field is required' : '')
        if (purchasedProducts.length <= 0) {
            alert('Please add purchased products.')
        }
        if (date && purchasedProducts.length > 0) {
            var response = await fetchData(id ? `api/v1/admin/purchases/${id}` : 'api/v1/admin/purchases', { date, remark, purchase_items: purchasedProducts.map(x => ({ type: x.type, qty: x.qty, purchasable_id: x.purchasable_id, id: x.id })) }, (id ? 'PUT' : 'POST'));
            if (response.ok) {
                history.push('/purchases');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <InputLabel shrink style={{ position: 'relative', top: 45 }}>Date *</InputLabel>
                                    <CustomInput
                                        // labelText="Date *"
                                        id="date"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'date',
                                            value: date || '',
                                            onChange: e => setDate(e.target.value),
                                            onBlur: e => setDateError(!date ? 'This field is required' : ''),
                                        }}
                                        errorText={dateError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardBody>
                                            <b>Purchased Products</b>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <CustomAutoComplete
                                                        labelText="Product *"
                                                        id="product_id"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        value={product}
                                                        onChange={(event, value) => setProduct(value)}
                                                        onBlur={() => setProductError(!product ? 'This field is required' : '')}
                                                        url={`api/v1/admin/products`}
                                                        labelField='name'
                                                        valueField='id'
                                                        errorText={productError}
                                                    />
                                                </GridItem>
                                                {
                                                    variations.length > 0 &&
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <CustomAutoComplete
                                                            labelText="Color *"
                                                            id="variation_id"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            value={variation}
                                                            onChange={(event, value) => setVariation(value)}
                                                            onBlur={() => setVariationError((variations.length > 0 && !variation) ? 'This field is required' : '')}
                                                            items={variations}
                                                            errorText={variationError}
                                                        />
                                                    </GridItem>
                                                }
                                                {
                                                    sizes.length > 0 &&
                                                    <GridItem GridItem xs={12} sm={12} md={4}>
                                                        <CustomAutoComplete
                                                            labelText="Size *"
                                                            id="size_id"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            value={size}
                                                            onChange={(event, value) => setSize(value)}
                                                            onBlur={() => setSizeError((sizes.length > 0 && !size) ? 'This field is required' : '')}
                                                            items={sizes}
                                                            errorText={sizeError}
                                                        />
                                                    </GridItem>
                                                }
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <CustomInput
                                                        labelText="Qty *"
                                                        id="qty"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            type: 'number',
                                                            value: qty || '',
                                                            onChange: e => setQty(e.target.value),
                                                            onBlur: e => setQtyError(!qty ? 'This field is required' : '')
                                                        }}
                                                        errorText={qtyError}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                    <Button color="primary" onClick={Add}>Add</Button>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem GridItem xs={12} sm={12} md={12}>
                                                    <DataTable
                                                        tableHeaderColor="primary"
                                                        tableHead={id ? ["Product", 'Variation', 'Size', 'Type', 'Qty', ''] : ["Product", 'Variation', 'Size', 'Type', 'Qty', "Action"]}
                                                        tableData={
                                                            purchasedProducts.map((purchasedProduct, index) => ({
                                                                data: [purchasedProduct.product, purchasedProduct.variation, purchasedProduct.size, purchasedProduct.type, purchasedProduct.qty],
                                                                customActions: (
                                                                    <Button className='iconbtn' color="danger" onClick={() => Delete(index)} tooltip={{ title: 'Delete' }}><DeleteIcon /></Button>
                                                                )
                                                            }))
                                                        }
                                                        disableCreate
                                                        disablePagination
                                                        disableSearch
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Remark"
                                        id="remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: remark || '',
                                            onChange: e => setRemark(e.target.value),
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div >
    );
}
export default withRouter(PurchaseForm);