import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'

export const CustomCheckBox = props => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.value}
                    name={props.name || props.label}
                    color={props.color || "primary"}
                    {...props}
                />
            }
            label={props.label}
        />
    )
}
