import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData } from "components/fetch";
import { fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import moment from 'moment';
import CustomAutoComplete from "components/CustomAutoComplete/CustomAutoComplete";
import { useCallback } from "react";
import DataTable from "components/Table/DataTable";
import { Delete, Delete as DeleteIcon } from "@material-ui/icons";
import { Array as ArrayFnc } from 'react-native-trifinity';
import { isObjectEmpty } from 'components/common';
import { postData } from "components/fetch";
import { InputLabel } from '@material-ui/core';

function Grounding({ history }) {
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [remark, setRemark] = useState('')
    const [product, setProduct] = useState(null)
    const [productError, setProductError] = useState('')
    const [variations, setVariations] = useState([])
    const [variation, setVariation] = useState(null)
    const [variationError, setVariationError] = useState('')
    const [sizes, setSizes] = useState([])
    const [size, setSize] = useState(null)
    const [sizeError, setSizeError] = useState('')
    const [currentQty, setCurrentQty] = useState(0)
    const [qty, setQty] = useState(0)
    const [qtyError, setQtyError] = useState('')
    const [groundingProducts, setGroundingProducts] = useState([])
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const GetGroundingProducts = async (page, startDate, endDate) => {
        var response = await getData(`api/v1/admin/groundings?page=${page}&start_date=${startDate}&end_date=${endDate}`);
        if (response.ok) {
            setGroundingProducts(response.data.data.map(x => ({
                date: x.date,
                type: x.type,
                before: x.before,
                after: x.after,
                // purchasable_id: x.purchasable_id,
                id: x.id,
                product: x.type === 'size' ? x.groundable_item.variation.product.name : x.type === 'variation' ? x.groundable_item.product.name : x.groundable_item.name,
                variation: x.type === 'size' ? x.groundable_item.variation.color : x.type === 'variation' ? x.groundable_item.color : '',
                size: x.type === 'size' ? x.groundable_item.name : '',
                remark: x.remark,
            })));
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
        }
    }

    // const GetVariations = async (product_id) => {
    //     if (product_id) {
    //         var response = await getData(`api/v1/admin/products/${product_id}/variations`)
    //         if (response.ok) {
    //             setVariations(response.data.data.map(x => ({ label: x.color, value: x.id })));
    //         }
    //     }
    // }

    const GetSizes = async (variation_id) => {
        if (variation_id) {
            var response = await getData(`api/v1/admin/variations/${variation_id}`)
            if (response.ok) {
                setSizes(response.data.data.sizes.map(x => ({ label: x.name, value: x.id, stock: x.stock })));
            }
        }
    }

    useEffect(() => {
        GetGroundingProducts(page, startDate, endDate);
        return () => {
            setRemark('');
        }
    }, [page, startDate, endDate])

    useEffect(() => {
        if (product) {
            // GetVariations(product.value)
            if (product.variations.length > 0) {
                setVariations(product.variations.map(x => ({ label: x.color, value: x.id, stock: x.stock })));
                setCurrentQty('');
            } else {
                setVariations([]);
                setVariation(null);
                setSizes([]);
                setSize(null);
                setCurrentQty(product.stock);
            }
        } else {
            setVariations([]);
            setVariation(null);
            setSizes([]);
            setSize(null);
            setCurrentQty('');
        }
    }, [product])

    useEffect(() => {
        if (variation) {
            GetSizes(variation.value)
        }
        setSizes([]);
        setSize(null);
    }, [variation])

    useEffect(() => {
        setCurrentQty(sizes.length === 0 ? variation ? variation.stock : product ? product.stock : '' : '');
    }, [sizes])

    useEffect(() => {
        setCurrentQty(size ? size.stock : '');
    }, [size])

    // useEffect(() => {
    //     console.log(variations)
    // }, [variations])

    useDidUpdateEffect(() => {
        setQtyError(!qty ? 'This field is required' : '')
        return () => {
            setQtyError('')
        }
    }, [qty])

    useDidUpdateEffect(() => {
        setProductError(!product ? 'This field is required' : '')
        return () => {
            setProductError('')
        }
    }, [product])

    const Add = async () => {
        setProductError(!product ? 'This field is required' : '');
        setVariationError((variations.length > 0 && !variation) ? 'This field is required' : '');
        setSizeError((sizes.length > 0 && !size) ? 'This field is required' : '');
        setQtyError((!qty || qty <= 0) ? 'This field is required' : '');
        if (product && product.value && (variations.length <= 0 || (variation && variation.value)) && (sizes.length <= 0 || (size && size.value)) && qty) {
            var groundingProduct = {
                "date": moment().format("YYYY-MM-DD"),
                "remark": remark,
                "groundable_id": size ? size.value : variation ? variation.value : product.value,
                "groundable_type": size ? 'size' : variation ? 'variation' : 'product',
                "stock": qty,
            }
            if (!isObjectEmpty(groundingProduct)) {
                var response = await postData('api/v1/admin/groundings', groundingProduct);
                if (response.ok) {
                    GetGroundingProducts(page, startDate, endDate);
                    setProduct(null);
                    setVariations([]);
                    setVariation(null);
                    setSizes([]);
                    setSize(null);
                    setQty('');
                    setCurrentQty('0');
                }
            }
        }
    }

    const Delete = (index) => {
        ArrayFnc.arrayRemoveByIndex(groundingProducts, index);
        setGroundingProducts(ArrayFnc.clone(groundingProducts));
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                {/* <GridItem xs={12} sm={12} md={4}>
                                    <InputLabel shrink style={{ position: 'relative', top: 45 }}>Date *</InputLabel>
                                    <CustomInput
                                        // labelText="Date *"
                                        id="date"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'date',
                                            value: date || '',
                                            onChange: e => setDate(e.target.value),
                                            onBlur: e => setDateError(!date ? 'This field is required' : ''),
                                        }}
                                        errorText={dateError}
                                    />
                                </GridItem> */}
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomAutoComplete
                                                labelText="Product *"
                                                id="product_id"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={product}
                                                onChange={(event, value) => setProduct(value)}
                                                onBlur={() => setProductError(!product ? 'This field is required' : '')}
                                                url={`api/v1/admin/products`}
                                                labelField='name'
                                                valueField='id'
                                                additionalFields={['stock', 'variations']}
                                                errorText={productError}
                                            />
                                        </GridItem>
                                        {
                                            variations.length > 0 &&
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomAutoComplete
                                                    labelText="Color *"
                                                    id="variation_id"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    value={variation}
                                                    onChange={(event, value) => setVariation(value)}
                                                    onBlur={() => setVariationError((variations.length > 0 && !variation) ? 'This field is required' : '')}
                                                    items={variations}
                                                    errorText={variationError}
                                                />
                                            </GridItem>
                                        }
                                        {
                                            sizes.length > 0 &&
                                            <GridItem GridItem xs={12} sm={12} md={4}>
                                                <CustomAutoComplete
                                                    labelText="Size *"
                                                    id="size_id"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    value={size}
                                                    onChange={(event, value) => setSize(value)}
                                                    onBlur={() => setSizeError((sizes.length > 0 && !size) ? 'This field is required' : '')}
                                                    items={sizes}
                                                    errorText={sizeError}
                                                />
                                            </GridItem>
                                        }
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={2}>
                                            <CustomInput
                                                labelText="Current Qty"
                                                id="current_qty"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: currentQty,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={2}>
                                            <CustomInput
                                                labelText="Qty To Change *"
                                                id="qty"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: 'number',
                                                    value: qty || '',
                                                    onChange: e => setQty(e.target.value),
                                                    onBlur: e => setQtyError(!qty ? 'This field is required' : '')
                                                }}
                                                errorText={qtyError}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Remark"
                                                id="remark"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: remark || '',
                                                    onChange: e => setRemark(e.target.value),
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <Button color="primary" onClick={Add}>Save</Button>
                                        </GridItem>
                                    </GridContainer>
                                    <Card>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem GridItem xs={12} sm={12} md={12}>
                                                    <b>Grounding History</b>
                                                    <DataTable
                                                        tableHeaderColor="primary"
                                                        tableHead={["Date", "Product", 'Variation', 'Size', 'Type', 'Qty(Before)', 'Qty(After)', 'remark', '']}
                                                        tableData={
                                                            groundingProducts.map((groundingProduct, index) => ({
                                                                data: [groundingProduct.date, groundingProduct.product, groundingProduct.variation, groundingProduct.size, groundingProduct.type, groundingProduct.before, groundingProduct.after, groundingProduct.remark],
                                                                customActions: <></>
                                                            }))
                                                        }
                                                        disableCreate
                                                        disableSearch
                                                        style={{ marginTop: 0 }}
                                                        count={count}
                                                        page={page}
                                                        onPaginationChange={async (event, value) => {
                                                            await setPage(value)
                                                        }}
                                                        customTableActions={(
                                                            <>
                                                                <GridItem md={4}>
                                                                    <InputLabel shrink style={{ position: 'relative', top: 45 }}>Start Date</InputLabel>
                                                                    <CustomInput
                                                                        className='pull-right'
                                                                        // labelText="State Date"
                                                                        id="startDate"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            type: 'date',
                                                                            value: startDate || '',
                                                                            onChange: e => setStartDate(e.target.value)
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                <GridItem md={4}>
                                                                    <InputLabel shrink style={{ position: 'relative', top: 45 }}>End Date</InputLabel>
                                                                    <CustomInput
                                                                        className='pull-right'
                                                                        // labelText="State Date"
                                                                        id="endDate"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            type: 'date',
                                                                            value: endDate || '',
                                                                            onChange: e => setEndDate(e.target.value)
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </>
                                                        )}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div >
    );
}
export default withRouter(Grounding);