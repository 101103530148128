import { useIsLoggedIn } from 'components/Hooks/useIsLoggedIn';
import React from 'react'
import { Redirect, useLocation, withRouter } from 'react-router-dom'
import Banners from './Banners';
import MainCarousel from './MainCarousel'
import { Card } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DealOfADay from './DealOfADay';
import MainPoster from './MainPoster';
import MoreBeautyForYou from './MoreBeautyForYou';

const Home = ({ history }) => {
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        {/* <MainCarousel /> */}
                        <MainPoster />
                        {/* <DealOfADay /> */}
                        <Banners />
                        <MoreBeautyForYou />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default withRouter(Home)
