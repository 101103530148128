import useFetch, { postFormData, deleteData } from 'components/fetch';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import resolution from 'components/resolution';
import { useScrollToTop } from 'components/ScrollToTop';
import React, { useEffect, useState } from 'react'

function MainCarousel(props) {
    const [images, setImages] = useState(null);
    const [refreshCount, setRefreshCount] = useState(0);
    useScrollToTop(images);

    var response = useFetch([refreshCount], `api/v1/admin/carousels`);
    useEffect(() => {
        if (response && response.ok) {
            setImages(response.data.data.map(x => ({ ...x.image, data_url: x.image.url })));
        }
    }, [response])

    const DeleteImage = async (id) => {
        var response = await deleteData(`api/v1/admin/carousels/${id}`);
        if (response.ok) {
        }
    }
    const PutImage = async (id, image) => { // id=image_id
        var response = await postFormData(`api/v1/admin/carousels/${id}`, { image });
        // console.log('PutImage response', response)
        if (response.ok) {
        }
    }
    const PostImage = async (image) => { // id=product_id
        var response = await postFormData(`api/v1/admin/carousels`, { image });
        // console.log('PostImage response', response)
        if (response.ok) {
        }
    }

    return (
        <div>
            <h5>Main carousel</h5>
            {
                images ?
                    <ImageUploader value={images}
                        labelText={'Main Carousel * - Click / Drop here'}
                        resolutionProps={resolution.mainCarousel}
                        onChange={(images, addUpdateIndex, action) => { setImages(images); console.log(addUpdateIndex, action) }}
                        onImageUpload={(imageList) => {
                            imageList.map(image => {
                                PostImage(image.file)
                            })
                        }}
                        onImageUpdate={(imageList) => {
                            imageList.map(image => {
                                PutImage(image.old.imageable_id, image.new.file)
                            })
                        }}
                        onImageRemove={(image) => {
                            if (image.id) {
                                DeleteImage(image.imageable_id)
                            }
                        }}
                    />
                    : <div style={{ width: '100%', height: 100 }}><span>Loading...</span></div>
            }
        </div>
    )
}

export default MainCarousel