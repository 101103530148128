import { Card, InputLabel } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import useFetch, { getData, postFormData, deleteData } from 'components/fetch';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import resolution from 'components/resolution';
import { useScrollToTop } from 'components/ScrollToTop';
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useDidUpdateEffect } from 'components/Hooks/useDidUpdateEffect';
import Button from "components/CustomButtons/Button.js";

function DealOfADay(props) {
    const [id, setId] = useState(null);
    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState('');
    const [end_date, setEnd_date] = useState('');
    const [end_dateError, setEnd_dateError] = useState('');

    const GetDeal = async (brand_id) => {
        var response = await getData(`api/v1/admin/deal-of-day?brand_id=${brand_id || ''}`);
        if (response.ok) {
            setImage(response.data.data.image.url);
            setEnd_date(moment(response.data.data.end_date).format('YYYY-MM-DDTHH:mm'));
            setId(response.data.data.id);
        }
    }
    const PostDeal = async (id, brand_id) => {
        var response = await postFormData(`api/v1/admin/deal-of-day` + (id ? `/${id}` : ''), { brand_id: brand_id || '', image: image?.file || image, end_date: moment(end_date).format('YYYY-MM-DD HH:mm:ss') });
        if (response.ok) {
            if (!id) {
                GetDeal(brand_id);
            }
        }
    }
    const DeleteDeal = async (id) => {
        if (id) {
            var response = await deleteData(`api/v1/admin/deal-of-day/${id}`);
            if (response.ok) {
                setImage(null);
                setEnd_date('');
                setId(null);
            }
        }
    }
    useEffect(() => {
        GetDeal(props.brand_id);
    }, [props.brand_id])

    const SaveDeal = async () => {
        if (image && end_date) {
            PostDeal(id, props.brand_id);
        }
    }

    // var response = useFetch([refreshCount], `api/v1/admin/carousels`);
    // useEffect(() => {
    //     if (response && response.ok) {
    //         setImages(response.data.data.map(x => ({ ...x.image, data_url: x.image.url })));
    //     }
    // }, [response])

    return (
        <div>
            <h5>Deal of a day</h5>
            <GridContainer>
                <GridItem xs={12} sm={7} md={7}>
                    <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="url" errorText={imageError}
                        resolutionProps={props.brand_id ? resolution.deal_of_a_day_brand : resolution.deal_of_a_day_home}
                        style={{ width: props.brand_id ? 525 : 570, height: 525 }}
                        unremoveable
                    />
                    {/* {
                        image ?
                            <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="url" errorText={imageError}
                                resolutionProps={props.brand_id ? resolution.deal_of_a_day_brand : resolution.deal_of_a_day_home}
                                style={{ width: props.brand_id ? 525 : 570, height: 525 }}
                                unremoveable
                            /> :
                            <SingleUpload value={''} onChange={image => setImage(image)} errorText={imageError}
                                resolutionProps={props.brand_id ? resolution.deal_of_a_day_brand : resolution.deal_of_a_day_home}
                                style={{ width: props.brand_id ? 525 : 570, height: 525 }}
                            />
                    } */}
                </GridItem>
                <GridItem xs={12} sm={5} md={5}>
                    <InputLabel shrink style={{ position: 'relative', top: 45 }}>Closing Deal Date *</InputLabel>
                    <CustomInput
                        // labelText="Closing Deal Date *"
                        id="name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: end_date || '',
                            onChange: e => setEnd_date(e.target.value),
                            onBlur: e => setEnd_dateError(!end_date ? 'This field is required' : ''),
                            type: 'datetime-local'
                        }}
                        errorText={end_dateError}
                    />
                    <Button color="primary" onClick={SaveDeal}>Save</Button>
                    <Button color="danger" onClick={() => DeleteDeal(id)}>Delete</Button>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default DealOfADay