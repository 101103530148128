import { Card } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import useFetch, { postFormData, deleteData } from 'components/fetch';
import { getData } from 'components/fetch';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useDidUpdateEffect } from 'components/Hooks/useDidUpdateEffect';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import resolution from 'components/resolution';
import { useScrollToTop } from 'components/ScrollToTop';
import React, { useEffect, useState } from 'react'
import Button from "components/CustomButtons/Button.js";

function MainPoster(props) {
    const [main_poster, setMain_poster] = useState(null);
    const [main_posterError, setMain_posterError] = useState('');
    const [link, setLink] = useState('');


    const GetBannerImage = async (type) => { // id=brand_id
        var response = await getData(`api/v1/admin/banners?type=${type}`);
        // console.log(response)
        if (response.ok) {
            var img = response.data.data.image.url;
            var url = response.data.data.url;
            switch (type) {
                case "main_poster": setMain_poster(img);setLink(url); break;
            }
        }
    }
    // const PostBannerImage = async (image, link, type) => { // id=brand_id
    //     if (image.file) {
    //         var response = await postFormData(`api/v1/admin/banners`, { type, image: image.file, url: link });
    //         console.log("banner", response)
    //         if (response.ok) {
    //         }
    //     }
    // }

    const Save = async () => {
        // console.log("banner1", main_poster)
        // console.log("banner2", main_poster.file)
        // if (main_poster.file) {
        var response = await postFormData(`api/v1/admin/banners`, { type: 'main_poster', image: main_poster.file, url: link });
        // console.log("banner3", response)
        if (response.ok) {
            alert("Image and Link Success");
        }
        // }
    }

    useEffect(() => {
        GetBannerImage('main_poster');
    }, [])

    // useDidUpdateEffect(() => {
    //     Save();
    // }, [main_poster])

    return (
        <div>
            <h5>Main Poster</h5>
            <GridContainer>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <SingleUpload value={main_poster} onChange={image => setMain_poster(image)} dataURLKey="url"  errorText={main_posterError}
                                    // resolutionProps={resolution.mainPoster}
                                    style={{ width: 900, height: 375 }}
                                    labelText={`${resolution.mainPoster.resolutionWidth}x${resolution.mainPoster.resolutionHeight}`}
                                    unremoveable
                                // labelText={'Main Poster'}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            {/* <SingleUpload value={main_poster} onChange={image => setMain_poster(image)} dataURLKey="url"  errorText={main_posterError}  /> */}
                                <CustomInput
                                    labelText="Link"
                                    id="link"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: link || '',
                                        onChange: e => setLink(e.target.value),
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <CardFooter>
                        <Button color="primary" onClick={Save}>Save</Button>
                    </CardFooter>
                </Card>
            </GridContainer>
        </div>
    )
}

export default MainPoster