import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import ImageUploader from "components/ImageUploader/ImageUploader";
import RichEditor from '@methodexists/me-rich-editor';

import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { FormControl } from "@material-ui/core";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import CustomAutoComplete from "components/CustomAutoComplete/CustomAutoComplete";
import { getData, putData, postFormData } from "components/fetch";
import { getBase64 } from "components/ImageUploader/ImageUploader";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { CustomCheckBox } from "components/CustomCheckBox/CustomCheckBox";
import resolution from "components/resolution";

const useStyles = makeStyles(styles);

function ProductForm({ history }) {
    const classes = useStyles();

    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [images, setImages] = useState([])
    const [imagesError, setImagesError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [barcode, setBarcode] = useState('')
    const [barcodeError, setBarcodeError] = useState('')
    const [sku, setSku] = useState('')
    const [skuError, setSkuError] = useState('')
    const [price, setPrice] = useState('0')
    const [priceError, setPriceError] = useState('')
    const [original_price, setOriginalPrice] = useState('')
    const [brand, setBrand] = useState(null)
    const [brandError, setBrandError] = useState('')
    const [category, setCategory] = useState(null)
    const [categoryError, setCategoryError] = useState('')
    const [sub_categories, setSub_categories] = useState([])
    const [tags, setTags] = useState([])
    const [new_arrival, setNew_arrival] = useState(0)
    const [featured, setFeatured] = useState(0)
    const [best_seller, setBest_seller] = useState(0)
    const [short_description, setShort_description] = useState('')
    const [short_descriptionError, setShort_descriptionError] = useState('')
    const [full_description, setFull_description] = useState('')
    const [full_descriptionError, setFull_descriptionError] = useState('')
    const [published, setPublished] = useState(0)

    const GetProduct = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/products/${id}`)
            if (response.ok) {
                var data = response.data.data;
                setName(data.name);
                setBarcode(data.barcode);
                setSku(data.sku);
                setPrice(data.price?.toString());
                setOriginalPrice(data.original_price?.toString());
                setBrand({ label: data.brand ? data.brand.name:null, value: data.brand ? data.brand.id:null });
                setCategory({ label: data.category ? data.category.name:null, value: data.category ? data.category.id:null });
                setSub_categories(data.sub_categories.map(x => ({ label: x.name, value: x.id })));
                setTags(data.tags.map(x => ({ label: x.name, value: x.id })));
                setNew_arrival(data.new_arrival);
                setFeatured(data.featured);
                setBest_seller(data.best_seller);
                setShort_description(data.short_description);
                setFull_description(data.full_description);
                setPublished(data.published);
            }
        }
    }

    useEffect(() => {
        setSub_categories([]);
    }, [category])

    useEffect(() => {
        GetProduct(id);
        return () => {
            setImages([]);
            setImagesError('');
            setName('');
            setNameError('');
            setBarcode('');
            setBarcodeError('');
            setSku('');
            setSkuError('');
            setPrice(0);
            setPriceError('');
            setOriginalPrice('');
            setBrand(null);
            setBrandError('');
            setCategory(null);
            setCategoryError('');
            setSub_categories([]);
            setTags([]);
            setNew_arrival(0);
            setFeatured(0);
            setBest_seller(0);
            setShort_description('');
            setShort_descriptionError('');
            setFull_description('');
            setFull_descriptionError('');
            setPublished(0);
        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])


    useDidUpdateEffect(() => {
        setSkuError(!sku ? 'This field is required' : '')
        return () => {
            setSkuError('')
        }
    }, [sku])

    useDidUpdateEffect(() => {
        setPriceError(!price ? 'This field is required' : '')
        return () => {
            setPriceError('')
        }
    }, [price])

    useDidUpdateEffect(() => {
        setBrandError(!brand ? 'This field is required' : '')
        return () => {
            setBrandError('')
        }
    }, [brand])

    useDidUpdateEffect(() => {
        setCategoryError(!category ? 'This field is required' : '')
        return () => {
            setCategoryError('')
        }
    }, [category])

    useDidUpdateEffect(() => {
        setShort_descriptionError(!short_description ? 'This field is required' : '')
        return () => {
            setShort_descriptionError('')
        }
    }, [short_description])

    useDidUpdateEffect(() => {
        setFull_descriptionError(!full_description ? 'This field is required' : '')
        return () => {
            setFull_descriptionError('')
        }
    }, [full_description])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        setSkuError(!sku ? 'This field is required' : '')
        setPriceError(!price ? 'This field is required' : '')
        setBrandError(!brand ? 'This field is required' : '')
        setCategoryError(!category ? 'This field is required' : '')
        setShort_descriptionError(!short_description ? 'This field is required' : '')
        setFull_descriptionError(!full_description ? 'This field is required' : '')
        setImagesError((!images || images.length <= 0) ? 'This field is required' : '')
        if (name && sku && price && brand && category && short_description && full_description) {
            var data = { name, sku,barcode, price,original_price, brand_id: brand.value, category_id: category.value, sub_category_ids: sub_categories.map(x => x.value), tag_ids: tags.map(x => x.value), new_arrival, featured, best_seller, short_description, full_description, published };
            if (id) { // PUT
                var response = await putData(`api/v1/admin/products/${id}`, data);
                if (response.ok) {
                    history.push('/products');
                } else {
                    if (response.data.errors && response.data.errors["sku"]) {
                        setSkuError(response.data.errors["sku"][0]);
                    }
                }
            } else { // POST
                if (images && images.length > 0) {
                    data = { ...data, images: images.map(x => x.file), stock: 0, new: 1 };
                    var response = await postFormData('api/v1/admin/products', data);
                    if (response.ok) {
                        history.push('/products');
                    } else {
                        if (response.data.errors && response.data.errors["sku"]) {
                            setSkuError(response.data.errors["sku"][0]);
                        }
                    }
                } else {
                    setImagesError((!images || images.length <= 0) ? 'This field is required' : '')
                }
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                {
                                    !id ?
                                        <GridItem xs={12} sm={12} md={12}>
                                            <ImageUploader
                                                labelText={'Products Images * - Click / Drop here'}
                                                value={images}
                                                onChange={(images, addUpdateIndex, action) => {  setImages(images) }}
                                                errorText={imagesError}
                                                resolutionProps={resolution.product}
                                            />
                                        </GridItem>
                                        : <></>
                                }
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Barcode *"
                                        id="barcode"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: barcode || '',
                                            onChange: e => setBarcode(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="SKU *"
                                        id="sku"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: sku || '',
                                            onChange: e => setSku(e.target.value),
                                            onBlur: e => setSkuError(!sku ? 'This field is required' : '')
                                        }}
                                        errorText={skuError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Price *"
                                        id="price"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'number',
                                            value: price || '',
                                            onChange: e => setPrice(e.target.value),
                                            onBlur: e => setPriceError(!price ? 'This field is required' : '')
                                        }}
                                        errorText={priceError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Original Price"
                                        id="original_price"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'number',
                                            value: original_price || '',
                                            onChange: e => setOriginalPrice(e.target.value),
                                            // onBlur: e => setOriginalPriceError(!original_price ? 'This field is required' : '')
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomAutoComplete
                                        labelText="Brand *"
                                        id="brand"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={brand}
                                        onChange={(event, value) => setBrand(value)}
                                        onBlur={e => setBrandError(!brand ? 'This field is required' : '')}
                                        url={`api/v1/admin/brands`}
                                        labelField='name'
                                        valueField='id'
                                        errorText={brandError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomAutoComplete
                                        labelText="Category *"
                                        id="category"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={category}
                                        onChange={(event, value) => setCategory(value)}
                                        onBlur={e => setCategoryError(!category ? 'This field is required' : '')}
                                        url={`api/v1/admin/categories`}
                                        labelField='name'
                                        valueField='id'
                                        errorText={categoryError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomAutoComplete
                                        labelText="SubCategories"
                                        id="sub_categories"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        multiple
                                        value={sub_categories}
                                        onChange={(event, value) => setSub_categories(value)}
                                        url={`api/v1/admin/categories/${(category?.value || '0')}/sub_categories`}
                                        labelField='name'
                                        valueField='id'
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomAutoComplete
                                        labelText="Tags"
                                        id="tags"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        multiple
                                        value={tags}
                                        onChange={(event, value) => setTags(value)}
                                        url={`api/v1/admin/tags`}
                                        labelField='name'
                                        valueField='id'
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Short Description *"
                                        id="shortdescription"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: short_description || '',
                                            onChange: e => setShort_description(e.target.value),
                                            onBlur: e => setShort_descriptionError(!short_description ? 'This field is required' : '')
                                        }}
                                        errorText={short_descriptionError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='New Arrival' value={new_arrival} onChange={e => setNew_arrival(e.target.checked ? 1 : 0)} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='Featured' value={featured} onChange={e => setFeatured(e.target.checked ? 1 : 0)} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='Best Seller' value={best_seller} onChange={e => setBest_seller(e.target.checked ? 1 : 0)} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <FormControl
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            className={classes.labelRoot}
                                        >
                                            Full Description *
                                        </InputLabel>
                                        <RichEditor
                                            value={full_description}
                                            onChange={value => setFull_description(value)}
                                            onImageUpload={file => getBase64(file).then(data => data)}
                                            style={{ marginTop: 50 }}
                                        />
                                    </FormControl>
                                    {full_descriptionError !== undefined ? (
                                        <InputLabel error shrink>{full_descriptionError}</InputLabel>
                                    ) : null}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='Publish' value={published} onChange={e => setPublished(e.target.checked ? 1 : 0)} />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default withRouter(ProductForm)